import Model from "./Model";
import moment from 'moment-timezone';

export default class Company extends Model{
    static all = async() => {
        const res = await this.runQueryNetworkOnly(allCompanies, {});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.companies.runQuery [COMPLETE]`);
        return res.data.companies.sort((a,b) => a.title.localeCompare(b.title));
    }

    static create = async(company) => {
        const res = await this.runMutation(CompanyCreate, {input: company});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Company.CompanyCreate.runMutation [COMPLETE]: ${JSON.stringify(res)}`);
        return res.data.createCompany;
    }

    static searchBy = async (key="uuid", value, query="") => {
        const data = await super.searchBy(CompanySearch, key, value, query);
        return data && data.companySearch.sort((a,b) => a.name.localeCompare(b.name));
    }

    static searchByCompanyUuid = async(companyUuid) => {
        return this.searchBy("uuid", companyUuid);
    }

    static searchByCompanyTitle = async(companyTitle) => {
        const allCompanies = await this.all();
        return allCompanies.filter(c => c.title.localeCompare(companyTitle) === 0); 
        // Avoids issue with searching for things with quotes in the title
        // return this.searchBy("title", companyTitle);
    }

    static fullExport = async (companyUuid) => {
        const data = await super.searchBy(CompanyFullExport, "uuid", companyUuid, "");
        return data && data.companySearch.sort((a,b) => a.name.localeCompare(b.name));
    }

    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", query=CompanyFilter, first, after, last, before) => {
        const data = await super.filterBy(query, searchList, sortField, sortOrder, first, after, last, before);
        const result = data && data.companyFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static filteredAnyUploadPropertyActivity = async (userId, companyUUID, companyGroupUUID, propertyName, startDate, endDate) => {
        const vars = {
            companyInput: {searchList: [
                {field:"uuid", value: companyUUID, comparison:"="},
                companyGroupUUID ? {field:"uuid", value: companyGroupUUID, comparison:"="} : null
            ].filter(x => x!=null)},
            propertiesFilterInput: {
                searchList: [
                    // ...(startDate !== null ? [{field:"last_upload_at", value: startDate, comparison:">"}] : []),
                    // ...(endDate !== null ? [{field:"last_upload_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                    ...(propertyName && propertyName.length > 0 ? [{field:"name", value: propertyName, comparison:"LIKE"}] : []),
                ],
                sortField: "last_upload_at",
                sortOrder: "asc",
                first: 50
                // limit: 1000
            }
        }


        const res = await this.runQueryNetworkOnly(CompanyPropertyActivityFilter, vars);
        console.log(res);
        const result = res.data && res.data.companyFilter
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static filteredPropertyActivity = async (userId, companyUUID, companyGroupUUID, propertyName, startDate, endDate) => {
        const vars = {
            companyInput: {searchList: [
                {field:"uuid", value: companyUUID, comparison:"="},
                companyGroupUUID ? {field:"uuid", value: companyGroupUUID, comparison:"="} : null
            ].filter(x => x!=null)},
            propertiesFilterInput: {
                searchList: [
                    ...(startDate !== null ? [{field:"last_upload_at", value: startDate, comparison:">"}] : []),
                    ...(endDate !== null ? [{field:"last_upload_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                    ...(propertyName && propertyName.length > 0 ? [{field:"name", value: propertyName, comparison:"LIKE"}] : []),
                ],
                sortField: "updated_at",
                sortOrder: "asc",
                first: 100
                // limit: 1000
            },
            propertiesValFilterInput: {
                searchList: [
                    ...(startDate !== null ? [{field:"recorded_at", value: startDate, comparison:">"}] : []),
                    ...(endDate !== null ? [{field:"recorded_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                ],
                sortField: "recorded_at",
                sortOrder: "desc",
                first: 500
                // limit: 1,
                // first: 1
            },
            propertiesChecksFilterInput: {
                searchList: [
                    // {field:"created_at", value: startDate, comparison:">"},
                    ...(startDate !== null ? [{field:"created_at", value: startDate, comparison:">"}] : []),
                    ...(endDate !== null ? [{field:"created_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                ],
                sortField: "created_at",
                sortOrder: "desc",
                limit: 1,
                first: 1
            },
            propertiesPointInfosFilterInput: {
                searchList: [],
                sortField: "uuid",
                sortOrder: "desc"
            },
            userId: userId
            // lastValUploadedInput: {searchList: [{field:"recorded_at", value: uploadCutoffDate, comparison:">"}]}
        }


        const res = await this.runQueryNetworkOnly(CompanyPropertyActivityFilter, vars);
        console.log(res);
        const result = res.data && res.data.companyFilter
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }


    static detailedFilteredPropertyActivity = async (userId, companyUUID, companyGroupUUID, propertyName, startDate, endDate) => {
        const vars = {
            companyInput: {
                searchList: [
                    {field: "uuid", value: companyUUID, comparison: "="},
                    companyGroupUUID ? {field: "uuid", value: companyGroupUUID, comparison: "="} : null
                ].filter(x => x != null)
            },
            propertiesFilterInput: {
                searchList: [
                    ...(startDate !== null ? [{field:"last_upload_at", value: startDate, comparison:">"}] : []),
                    ...(endDate !== null ? [{field:"last_upload_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                    ...(propertyName && propertyName.length > 0 ? [{field:"name", value: propertyName, comparison:"LIKE"}] : []),
                ],
                sortField: "updated_at",
                sortOrder: "asc",
                first: 100
                // limit: 1000
            },
            propertiesValFilterInput: {
                searchList: [
                    ...(startDate ? [{field: "recorded_at", value: startDate, comparison: ">"}] : []),
                    ...(endDate ? [{field: "recorded_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison: "<"}] : []),
                    {field: "archived", value: 0, comparison: "="},
                ],
                sortField: "recorded_at",
                sortOrder: "desc",
                first: 10000
            },
            propertiesChecksFilterInput: {
                searchList: [
                    ...(startDate ? [{field: "created_at", value: startDate, comparison: ">"}] : []),
                    ...(endDate ? [{field: "created_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison: "<"}] : []),
                    {field: "archived", value: 0, comparison: "="},
                ],
                sortField: "created_at",
                sortOrder: "desc",
                first: 1
            },
            userId: userId
        };

        const res = await this.runQueryNetworkOnly(DetailedCompanyPropertyActivityFilter, vars);
        const result = res.data && res.data.companyFilter;

        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        };
    };

    static detailedFilteredPropertyUuidActivity = async (userId, companyUUID, companyGroupUUID, propertyName, startDate, endDate) => {
        const vars = {
            companyInput: {
                searchList: [
                    {field: "uuid", value: companyUUID, comparison: "="},
                    companyGroupUUID ? {field: "uuid", value: companyGroupUUID, comparison: "="} : null
                ].filter(x => x != null)
            },
            propertiesFilterInput: {
                searchList: [
                    ...(startDate !== null ? [{field:"last_inspected_at", value: startDate, comparison:">"}] : []),
                    ...(endDate !== null ? [{field:"last_inspected_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                    ...(propertyName && propertyName.length > 0 ? [{field:"name", value: propertyName, comparison:"LIKE"}] : []),
                ],
                sortField: "last_inspected_at",
                sortOrder: "asc",
                first: 900
            },
            propertiesChecksFilterInput: {
                searchList: [
                    ...(startDate ? [{field: "created_at", value: startDate, comparison: ">"}] : []),
                    ...(endDate ? [{field: "created_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison: "<"}] : []),
                    {field: "archived", value: 0, comparison: "="},
                ],
                sortField: "created_at",
                sortOrder: "desc",
                first: 1
            },
            userId: userId
        };
        const res = await this.runQueryNetworkOnly(DetailedCompanyPropertyUuidActivityFilter, vars);
        console.log("This is the res from detailedFilteredPropertyUuidActivity", res);
        const result = res.data && res.data.companyFilter;

        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        };
    };

    // Helper function to create date ranges
    static createDateRanges(startDate, endDate, chunkSizeInDays) {
        let start = moment(startDate);
        const end = moment(endDate);
        const ranges = [];
    
        while (start.isBefore(end)) {
            let next = moment(start).add(chunkSizeInDays, 'days');
            if (next.isAfter(end) || next.isSame(end)) {
                next = end;
                ranges.push({
                    start: start.format('YYYY-MM-DD'),
                    end: next.format('YYYY-MM-DD') 
                });
            } else {
                ranges.push({
                    start: start.format('YYYY-MM-DD'),
                    end: next.subtract(1, 'day').format('YYYY-MM-DD')
                });
            }
            start = next.add(1, 'day'); // Ensure the next start date is correctly set
        }
    
        return ranges;
    }

    static update = async(fields) => {
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update company - no uuid specified")
            return
        }
        const res = await this.runQueryNetworkOnly(UpdateCompany, {input: {
            uuid: fields.uuid,
            company_targets: fields.company_targets,
            
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Company.updateCompany.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }
 

    static filteredProperty = async (userId, companyUUID, companyGroupUUID, propertyName, startDate) => {
        const vars = {
            companyInput: {searchList: [
                {field:"uuid", value: companyUUID, comparison:"="},
                companyGroupUUID ? {field:"uuid", value: companyGroupUUID, comparison:"="} : null
            ].filter(x => x!=null)},
            propertiesFilterInput: {
                searchList: [
                    ...(startDate !== null ? [{field:"last_upload_at", value: startDate, comparison:">"}] : []),
                    {field:"archived", value: 0, comparison:"="},
                    ...(propertyName && propertyName.length > 0 ? [{field:"name", value: propertyName, comparison:"LIKE"}] : []),
                ],
                sortField: "updated_at",
                sortOrder: "asc",
                first: 100
                // limit: 1000
            },
            propertiesPointInfosFilterInput: {
                searchList: [],
                sortField: "uuid",
                sortOrder: "desc"
            },
            userId: userId
        }

        const res = await this.runQueryNetworkOnly(CompanyPropertyFilter, vars);
        console.log(res);
        const result = res.data && res.data.companyFilter
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }


    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": `${uuid}`, "comparison": "="}
        ], "uuid", "asc", 1);
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }
}

const allCompanies = `
    query{
        companies{
            id
            uuid
            name
            title
            company_targets
        }
    }
`;

const CompanySearch = `
    query CompanySearch($input:SearchInput){
        companySearch(input: $input){
            id
            uuid
            name
            company_targets
        }
    }
`;

const UpdateCompany = `
mutation updateCompany($input: CompanyUpdateInput){
    updateCompany(input: $input){
      uuid
      company_targets
    }
  }
`

const CompanyCreate = `
    mutation CreateCompany($input:CompanyInput){
        createCompany(input: $input){
            id
            uuid
            name
            title
        }
    }
`;

const CompanyFullExport = `
query CompanySearch($input:SearchInput){
    companySearch(input: $input){
        id
        uuid
        name
        title
        company_targets
        external_uuid
        property {
            company_id
            uuid
            name
            street1
            street2
            city
            state
            zip
            note
            external_uuid
            equipment{
                property_id
                propert_uuid
                uuid
                name
                model_num
                serial_num
                notes
                make
                system_id
                criticality
                year
                external_uuid
                building
                floor
                area

            }
        }
    }
}
`;

/*
    $lastValUploadedInput: FilterConnectionInput,

lastValUploaded(input: $lastValUploadedInput){
                    recorded_at
                }
*/


const CompanyPropertyFilter = `
query CompanyFilter(
    $companyInput : FilterConnectionInput,
    $propertiesFilterInput: FilterConnectionInput,
    $userId: Int!
){
    companyFilter(input: $companyInput){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
        edges{
            node{
            uuid
            name
            company_targets
            companyGroup {
                title
            }
            propertiesFilter(input: $propertiesFilterInput){
                uuid
                external_uuid
                id
                name
                updated_at
                street1
                street2
                lat
                lng
                city
                state
                zip
                max
                evalNum
                last_upload_at
                last_evaluation_at
                last_evaluation_user_id
                sheets(user_id: $userId) {
                    uuid
                }
                lastReportRun {
                    created_at
                    context
                }
                image {
                    bucket
                    key
                    region
                }
            }
        }
        }
    }
}
`

const CompanyFilter = `
query CompanyFilter($input: FilterConnectionInput){
    companyFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            id
            uuid
            name
            title
            email
            company_targets
            external_uuid
            companyGroup {
                title
                uuid
            }
            properties{
                uuid
                name
                updated_at
            }      

        }
      }
    }
  }
`


const CompanyPropertyActivityFilter = `
query CompanyFilter(
    $companyInput : FilterConnectionInput,
    $propertiesFilterInput: FilterConnectionInput
){
    companyFilter(input: $companyInput){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
        edges{
            node{
                uuid
                name
                company_targets
                companyGroup {
                    title
                }
                propertiesFilter(input: $propertiesFilterInput){
                    uuid
                    id
                    name
                    updated_at
                    street1
                    street2
                    lat
                    lng
                    city
                    state
                    zip
                    max
                    evalNum
                    last_evaluation_at
                    last_evaluation_user_id
                    last_upload_at
                    image {
                        bucket
                        key
                        region
                    }
                }
            }
        }
    }
}
`
const DetailedCompanyPropertyActivityFilter = `
query DetailedCompanyFilter(
    $companyInput: FilterConnectionInput,
    $propertiesFilterInput: FilterConnectionInput,
    $propertiesValFilterInput: FilterConnectionInput,
    $propertiesChecksFilterInput: FilterConnectionInput
    $userId: Int!
) {
    companyFilter(input: $companyInput) {
        edges {
            node {
                uuid
                name
                company_targets
                companyGroup {
                    title
                }
                propertiesFilter(input: $propertiesFilterInput) {
                            uuid
                            id
                            name
                            updated_at
                            street1
                            street2
                            lat
                            lng
                            city
                            state
                            external_uuid
                            country
                            note
                            zip
                            last_upload_at
                            routine_uuid
                            last_evaluation_at
                            last_evaluation_user_id
                            
                            figs {
                                id
                                uuid
                                created_at
                                key
                                parent_key
                                name
                                updated_at
                                system_id
                                make
                                notes
                                year
                                model_num
                                serial_num
                                criticality
                                fig_image_key
                                fig_image_ext
                                evaluations{
                                    id
                                    uuid
                                    fig_id
                                    started_at
                                    ended_at
                                    level
                                    sound_level
                                    comments
                                    rec
                                    net_samples_uuid
                                    recorded_yhat_json
                                    hash
                                    user_id
                                }
                                sheets {
                                    id
                                    uuid
                                    global
                                    company_id
                                    property_uuid
                                    system_type_id
                                    system_id
                                    name
                                    tasks {
                                        id
                                        created_at
                                        uuid
                                        name
                                        sheet_uuid
                                        type_code
                                        section_uuid
                                    }
                                    sections {
                                        id
                                        uuid
                                        name
                                        sheet_uuid
                                    }
                                }
                                checks(input: $propertiesChecksFilterInput){
                                    edges{
                                        node{
                                            created_at
                                            uuid
                                            task_uuid
                                            type_code
                                            fig_uuid
                                            name
                                        }
                                    }
                                    totalCount
                                }
                               
                            }

                            vals(input: $propertiesValFilterInput) {
                                edges {
                                    node {
                                        point_num
                                        recorded_at
                                        wav{
                                            unit_dec
                                            user {
                                                full_name
                                                uuid
                                                id
                                            }
                                        }
                                    }
                                }
                                totalCount
                            }
                            checks(input: $propertiesChecksFilterInput) {
                                edges {
                                    node {
                                        created_at
                                        uuid
                                        user {
                                            uuid
                                            id
                                            full_name
                                        }
                                    }
                                }
                                totalCount
                            }
                            warningMode {
                                id
                                recorded_at
                                overb
                                reviewed_at
                            }
                            lastValUploaded {
                                recorded_at
                                uuid
                                wav {
                                    user {
                                        full_name
                                        uuid
                                        id
                                    }
                                }
                            }
                            lastReportRun {
                                created_at
                                context
                            }
                            sheets(user_id: $userId) {
                                id
                                uuid
                                global
                                company_id
                                property_uuid
                                system_type_id
                                system_id
                                name
                                tasks {
                                    id
                                    created_at
                                    uuid
                                    name
                                    sheet_uuid
                                    type_code
                                    section_uuid
                                }
                                sections {
                                    id
                                    uuid
                                    name
                                    sheet_uuid
                                }
                            }
                            
                            image {
                                bucket
                                key
                                region
                            }
                        
                    
                }
            }
        }
    }
}
`;

const DetailedCompanyPropertyUuidActivityFilter = `
query DetailedCompanyFilter(
    $companyInput: FilterConnectionInput,
    $propertiesFilterInput: FilterConnectionInput,
    $propertiesChecksFilterInput: FilterConnectionInput
) {
    companyFilter(input: $companyInput) {
        edges {
            node {
                uuid
                name
                company_targets
                companyGroup {
                    title
                }
                propertiesFilter(input: $propertiesFilterInput) {
                    uuid
                    id
                    name  
                    last_upload_at
                    last_updated_at
                    last_inspected_at
                    updated_at
                    checks(input: $propertiesChecksFilterInput){
                        edges{
                            node{
                                created_at
                                uuid
                                user {
                                    uuid
                                    id
                                    full_name
                                }
                            }
                        }
                        totalCount
                    }
                }
            }
        }
    }
}
`;
