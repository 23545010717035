import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  BrowserRouter as Router,
  Link,
  useParams, 
  useHistory

} from "react-router-dom";
import '../../styles/grid.css';
import {NavSidebar} from './NavSidebar';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { FcInspection, FcTimeline, FcLibrary, FcBiohazard, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness, FcDownload } from 'react-icons/fc';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form } from 'react-bootstrap';
import Utils from '../../common/Utils';
import TaskingSheet from '../../models/TaskingSheet';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import { ImSpinner3 } from 'react-icons/im';
import { toast } from 'react-toastify';
import Check from '../../models/Check';
import Equipment from '../../models/Equipment';
import User from '../../models/User';

import { MdArrowBack } from 'react-icons/md';


import ExpandedCheckAnalyticsRow from './ExpandedCheckAnalyticsRow';
import {useFilterContext} from '../../context/SharedFilterContext';



const TaskingSheetTable = (props) => {
  const {setShowTaskingSheetTable  } = props;

  const {
    user,
    isSuperAdmin,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher
  } = useAuth();
  let {sheetUUID: providedSheetUUID} = useParams();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const history = useHistory();


  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && user.selectedProperty && user.selectedProperty.uuid) ? [user.selectedProperty.uuid] : []
    )
  );
  const { startDate, endDate, selectedProperty } = useFilterContext();
  const [sheets, setSheets] = useState([]);

  const [checkData, setCheckData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedSheetUUID, setSelectedSheetUUID] = useState(providedSheetUUID);
  const [selectedSheet, setSelectedSheet] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const updateSingleUpload = (updatedSheet) => {
    const sheetsList = sheets.length > 0 ? sheets : [];
    const indexOfExistingUpload = sheetsList.findIndex(x => x.uuid === updatedSheet.uuid);
    if (indexOfExistingUpload === -1){
      console.log("Couldn't find upload row")
      return
    }
    sheetsList[indexOfExistingUpload] = {...sheetsList[indexOfExistingUpload], ...updatedSheet};
    setSheets([...sheetsList]);
  }
  
  const updateSheet = async (row) => {
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await TaskingSheet.update({
      uuid: row.uuid, 
      global: row.global,
      default: row.default
    });
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }


const getFigNameHierarchy = (row) => {
  return row.fig && row.fig.name ? row.fig.name : '';
}
const getUserName = (row) => {
  return row.user && row.user.full_name ? row.user.full_name : '';
}


const getSheetName = async (check) => {
  // loop through sheets
  for (let sheet of sheets) {
      // check if any task in the current sheet matches the check's task_uuid
      const matchingTask = sheet.tasks.find(task => task.uuid === check.task_uuid);
      
      if (matchingTask) {
          return sheet.name;
      }
  }
  return '';  // return empty string if no matching sheet is found
}



  const columns = React.useMemo(() => 
  [
    {
      name: `Equipment`,
      selector: row => row.figNameHierarchy || '',
      cell: row => <span>{row.figNameHierarchy || ''}</span>,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: 'Sheet Name',
      selector: row => row.sheetName || '',
      cell: row => <span>{row.sheetName || ''}</span>,
      sortable: true,
      center: true,
      compact: true
  },
  
    {
      name: 'Task Name',
      selector: row => row.task && row.task.name,
      sortable: true,
      center: true,
      compact: true,
      width: '16em'
    },
    {
      name: `Value`,
      selector: cell => Check.formatCheckValueTextOnly(cell),
      cell: cell => {
        return <span>{Check.formatCheckValue(cell)}</span>
      },
      sortable: true,
      center: true,
      compact: true,
      wrap: true,
      width: '20em'
    },
    {
      name: 'Created At',
      selector: row => moment(row.created_at).unix(),
      cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
      sortable: true,
      center: true,
      compact: true,
      
    }, 
    // {
    //   name: 'Recorded At',
    //   selector: row => moment(row.recorded_at).unix(),
    //   cell: row => <span>{moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
    //   sortable: true,
    //   center: true,
    //   compact: true,
      
    // }, 
    {
      name: 'User',
      selector: row => row.userName || '',
      cell: row => <span>{row.userName || ''}</span>,
      sortable: true,
      center: true,
      compact: true
    },
  ].filter(x => x!=null), [JSON.stringify(checkData)]
);
  


  useEffect(() => {
    refreshSheetInformation();
    //loadChecks(); 
  }, [])

  useEffect(() => {
    refreshSingleSheetDetails(selectedSheetUUID);
  }, [selectedSheetUUID])


  const loadChecks = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return;
    }
  
    setLoading(true);

    const newChecks = await Check.filterBy([
      {field: 'archived', value: 0, comparison: "="},
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
    ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true );

    console.log("In load checks, this is the checks BEFORE filter", newChecks); 

    let checkList = newChecks.data || [];
    let filteredChecks = checkList.filter(item => {
      const checkDate = moment(item.created_at); // Adjusted to use created_at
      const adjustedEndDate = moment(endDate).add(1, 'days');
      return checkDate.isBetween(moment(startDate), adjustedEndDate, null, '[]');
  });

  console.log("In load checks, this is the checks AFRER filter", filteredChecks); 

  
 // Fetch equipment names
//  for (let check of filteredChecks) {
//   const nameHierarchy = await getFigNameHierarchy(check);
//   check.figNameHierarchy = nameHierarchy;

//   const userName = await getUserName(check);
//   check.userName = userName;
// }



for (let check of filteredChecks) {
  const nameHierarchy = getFigNameHierarchy(check);
  check.figNameHierarchy = nameHierarchy;

  const userName = getUserName(check);
  check.userName = userName;

  const sheetName = await getSheetName(check);  // add await here
  check.sheetName = sheetName;
}

  setCheckData(filteredChecks); 
  setLoading(false);
  }


  const refreshSheetInformation = async () => {
    setLoading(true);
    let results = await TaskingSheet.all();
    results = results.filter(sheet => sheet.company_id === user.company_id || sheet.global)
    console.log("This is the results from the tasking sheet", results); 
    setSheets(results)
    setLoading(false);
    loadChecks(); 
  }

  const refreshSingleSheetDetails = async (uuid) => {
    if (!uuid) return;
    const fullSheetDetails = await TaskingSheet.fullSheet(uuid)
    setSelectedSheet(fullSheetDetails);
  }

  const ExpandedRowComponent = (props) => {
    const expandedRow = props.data;
    return (
      <>
        {expandedRow.uuid !== selectedSheet.uuid ? <ImSpinner3 className="icon-spin" style={{fontSize: 25}}/> : 
        <div className="w-100 d-flex flex-row justify-content-start align-items-top flex-wrap ml-5 mt-5">  
          <ul className="list-group">
            <li className="list-group-item">Sheet: {selectedSheet.name}</li>
            {(selectedSheet.tasks || []).map(task => 
                <ul className="list-group pl-5">
                  <li className="list-group-item">Sheet Task: {task.name} <span className="badge float-right ml-5">{task.type_code}</span></li>
                </ul>
            )}
            <div className="mt-5"/>
            {(selectedSheet.sections || []).map(section =>
                <ul className="list-group pl-5">
                  <li className="list-group-item">Section: {section.name}</li>
                  {(section.tasks || []).map(task => 
                      <ul className="list-group pl-5">
                        <li className="list-group-item">Task: {task.name} <span className="badge float-right ml-5">{task.type_code}</span></li>
                      </ul>
                  )}
                </ul>
            )}
          </ul>
        </div>
      }
      </>
    );
  }

  return (
    <>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        {/* <NavSidebar/> */}
        <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
         

        {<div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                  setShowTaskingSheetTable(false);
                    history.push(`/inspections/${selectedPropertyUUIDs}`)
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Tasking Sheet Checks - {selectedProperty.name} </h4>
                   
                    
                </div>
            </div>}


            <div className="d-flex flex-row justify-content-center align-items-top flex-wrap">  
          

<DataTable
                columns={columns}
                data={checkData}
                noDataComponent = {"No Tasking Sheet Data Found"}
                responsive={true}
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRows
                onRowExpandToggled={(expanded, row) => {
                  if (expanded){
                    setSelectedSheetUUID(row.uuid)
                  }
                }}
                expandableRowsComponent={(props) => ExpandedCheckAnalyticsRow(props)}
                defaultSortAsc={false}
                defaultSortFieldId="created_at"
                disabled={loadingRowUUIDs.length > 0}
              />
            </div>
        </div>
    </div>

    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={() => {
        const columnNames = columns.map(col => `'${col.name}'`)
        const dataRowsAsTextOnly = checkData.map(row => columns.map(col => col.selector ? `${col.selector(row)}` : ``))
        
        const csvCombined = [
          columnNames.join(','),
          ...(dataRowsAsTextOnly || []).map(row => row.join(','))
        ].join('\n');
          
        let element = document.createElement('a');
        element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
        element.setAttribute('download', `export.csv`);
        document.body.appendChild(element);
        element.click();
      }}>
      <FcDownload/>
    </button>
  </div>

    </>
  )
}

export default TaskingSheetTable;