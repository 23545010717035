import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Property from '../../models/Property';
import Equipment from '../../models/Equipment';

import {
  BrowserRouter as Router,
  useParams,
  Route,
  useHistory,
  Link,
  useLocation
} from "react-router-dom";
import EquipmentGrid from './EquipmentGrid';
import { OverlayTrigger, Tooltip, Modal, Dropdown, Button, Form } from 'react-bootstrap';
import {MdArrowBack} from 'react-icons/md';
import PointAnalyticsTable from './PointAnalyticsTable';
import _ from 'lodash';
import PointCard from './PointCard';
import { useFilterContext } from "../../context/SharedFilterContext";
import moment from 'moment';
import { FcGrid, FcDocument, FcRefresh} from 'react-icons/fc';
import SendReportModal from './SendReportModal';
import { EditModal } from './GenericEditModal';
import { ImSpinner3 } from 'react-icons/im';
import PropertyEditScreen from '../property/PropertyEdit';
import EquipmentEditScreen from '../equipment/EquipmentEdit';
import TaskGrid from './TaskGrid';
import TaskingSheetCard from './TaskingSheetCard';
import EvaluationTableCard from './EvaluationTableCard';
import PropertyTableCard from './PropertyTableCard';
import PointTableCard from './PointTableCard';
import StatusBar from './StatusBar';

import ComponentsCard from './ComponentsCard';
import SectionTaskGrid from './SectionTaskGrid';
import MediaViewer from './MediaViewer';
import TaskingSheetTable from './TaskingSheetTable';
import CheckTable from './CheckTable';
import PointInspectionTable from './PointInspectionTable';
import EvaluationCreate from '../evaluation/EvaluationCreate';
import DatePicker from 'react-date-picker';
import Check from '../../models/Check';
import Val from '../../models/Val';

import Utils from "../../common/Utils";
import { ToastContainer, toast } from 'react-toastify';
import MultipointEvaluation from '../../models/MultipointEvaluation';



export const PropertyEditModal = (props) => {
  const {options} = props;
  return (
      <Modal show={options.title} onHide={options.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>{options.title || 'Edit'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PropertyEditScreen uuid={options.value} onHide={options.onHide}>

            </PropertyEditScreen>
        </Modal.Body>
      </Modal>


  )
}



export const EquipmentEditModal = (props) => {
  
  const {options} = props;
  const {
    selectedProperty
  } = useFilterContext();
  return (
      <Modal show={options.title} onHide={options.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>{options.title || 'Edit'}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{width: 500}} >
            <EquipmentEditScreen 
              uuid={options.value} 
              property={selectedProperty} 
              parentEquipment={options.parentEquipment} 
              onHide={options.onHide}
              hideImage={true}

            />
        </Modal.Body>
      </Modal>
  )
}





export const EvaluationEditModal = (props) => {
  const { options, refresh, onEvaluationCreated } = props;
  return (
      <Modal show={options.title} onHide={options.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>{options.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EvaluationCreate
              selectedEquipment={options.selectedEquipment}
              onHide={options.onHide}
              refreshEquipment={refresh}
              onEvaluationCreated={onEvaluationCreated}
            />
        </Modal.Body>
      </Modal>
  )
}

const InspectionEquipmentHierarchy = (props) => {
  const { 
    PointTableComponent, 
    EvaluationTableComponent,
    EquipmentEditComponent, 
    EquipmentDuplicateComponent,
    EquipmentAddComponent,
    hideAnalysisColors, 
    hideExpandedPointAnalysisColors,
    hideEndDate,
    hideStartDate,
    hideDateSelector,
    hideLayoutButton,
    hideSheetsSection,
    hideValCheck,
    backPath,
    sortByInspections,
    showSendReport,
    sortByName,
    sortByAnalysis,
    showPropertyEdit,
    showPropertyAdd,
    showEquipmentEdit,
    showEquipmentAdd,
    allowHidingComponentSection,
    allowHidingPointsSection,
    hideValCheckNumerator,
    hideValInspectionCountNumerator,
    isInspectionPage 
  } = props;
  
  const { user } = useAuth();
  const history = useHistory();

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedProperty,
    selectedRoutine,
    setSelectedProperty,
    setSelectedRoutine,
    propertyEquipmentList,
  } = useFilterContext();

  if (!startDate){
    setStartDate(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  }
  if (!endDate){
    setEndDate(moment().endOf('day').format('YYYY-MM-DD'));
  }

  let {
    propertyUUID
  } = useParams();

  const [totalEvaluations, setTotalEvaluations] = useState(0);
  const [totalChecks, setTotalChecks] = useState(0);
  const [totalMsiInspectionsState, setTotalMsiInspections] = useState(0);

  const [loadingTask, setLoadingTask] = useState(false);

  
  const [selectedRootEquipment, setSelectedRootEquipment] = useState(null);
  const [selectedSubcomponentList, setSelectedSubcomponentList] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedCheck, setSelectedCheck] = useState(null);

  const [editEquipmentModal, setEditEquipmentModal] = useState({});
  const [editPropertyModal, setEditPropertyModal] = useState({});
  const [editEvaluationModal, setEditEvaluationModal] = useState({});

  const [showEquipmentAddScreen, setShowEquipmentAddScreen] = useState(false);
  const [showEquipmentDuplicateScreen, setShowEquipmentDuplicateScreen] = useState(false);

  const [isValidMultipoint, setIsValidMultipoint] = useState(false);
  const [showMPeAlreadyRunButton, setShowMPeAlreadyRunButton] = useState(false);
  const [isLoadingMPe, setIsLoadingMPe] = useState(true);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState({});
  const [sendReportModal, setSendReportModal] = useState({});
  const [lastModifiedEquipment, setLastModifiedEquipment] = useState(null);

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);


  const [parentEquipment, setParentEquipment] = useState(null);
  const [value, setValue] = useState(null);

  const [showPointTable, setShowPointTable] = useState(false);
  const [showEvaluationTable, setShowEvaluationTable] = useState(false);
  const [showPropertyTable, setShowPropertyTable] = useState(false);


  const [showComponents, setShowComponents] = useState(false);
  const [showMode, setShowMode] = useState('Showing Inspected'); // By default it's 'Showing Inspected'
  const [taskNumber, setTaskNumber] = useState(0);
  const [valChecksCount, setValChecksCount] = useState(0);

 const [evaluationCreated, setEvaluationCreated] = useState(false);
 const [isFiltered, setIsFiltered] = useState(false); // State to track filtering

 const handleEvaluationCreated = () => {
     setEvaluationCreated(prev => !prev); // Toggle to trigger effect
 };


  useEffect(() => {
    setSelectedRootEquipment(null);
    setSelectedSubcomponentList([]);
    setSelectedPoint(null);
  }, [(selectedProperty || {}).uuid]);

  const sendReport = (propertyUUID) => {
    setSendReportModal({
      context: {
        itemReportInput: {
          propertyUUID: propertyUUID,
        }
      },
      show: true,
      onHide: () => setSendReportModal({})
    })
  }

  const fetchProperty = async () => {
    setLoading(true);
    const fullProperty = await Property.getFilterablePropertyByUUID(propertyUUID, user.id, startDate);
    setSelectedProperty(fullProperty);
    fullProperty.routines.map(routine => {
      if (selectedRoutine && routine.uuid === selectedRoutine.uuid){
        setSelectedRoutine(routine);
      }
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchProperty();
  }, [propertyUUID, startDate]);
  
  const lastSelectedEquipment = React.useMemo(
    () => _.last([selectedRootEquipment, ...(selectedSubcomponentList || [])]
  ), [JSON.stringify(selectedRootEquipment), JSON.stringify(selectedSubcomponentList)]);

  useEffect(() => {
    loadChecks(); 
    let checkCounts = lastSelectedEquipment?.vals && Object.keys(_.groupBy(lastSelectedEquipment.vals?.edges, val => val.node && val?.node?.point_num)).length;
    setValChecksCount(checkCounts);


  }, [lastSelectedEquipment]);



  const loadChecks = async () => {
    console.log("LOAD CHECKS FOR THIS EQUIPMENT ABOUT TO START", lastSelectedEquipment?.name);
        // let sections = lastSelectedEquipment?.sheets.length !== 0 ? lastSelectedEquipment?.sheets[0].sections : [];
    // let tasks = lastSelectedEquipment?.sheets.length !== 0 ? lastSelectedEquipment?.sheets[0].tasks : [];
    // const taskUUIDs = [...(sections?.map(section => section.tasks && section.tasks.map(task => task.uuid)) || []), ...(tasks?.map(task => task.uuid) || [])].filter(x => x !== undefined);
    const figChecks = await Check.filterBy([
      // {field: 'task_uuid', value: JSON.stringify(taskUUIDs), comparison: "IN"},
      {field: 'fig_uuid', value: lastSelectedEquipment?.uuid, comparison: "="},
      {field: "archived", value: 0, comparison: "="},
    ], "created_at", "desc", 250, undefined, undefined, undefined, true );
    let resultChecks = figChecks.data
    if (startDate && endDate){
      const oneDayAfterEndDate = moment(endDate).add(1, 'day');
      resultChecks = resultChecks.filter(x => moment.tz(x.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate));
    }
    resultChecks = resultChecks.filter(x => x.fig_uuid === lastSelectedEquipment?.uuid)
    setTaskNumber(resultChecks.length);
    console.log("This is the task number for this equipment", resultChecks.length, lastSelectedEquipment?.name);
    // setChecks(resultChecks);
  }
  

  const isDateInRange = (date, startDate, endDate) => {
    const oneDayAfterEndDate = moment(endDate).add(1, 'day');
    return moment.tz(date, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate, undefined, '[]');
  };

  useEffect(() => {
    // Flatten equipment list to also include all subcomponents
  const flattenedEquipments = propertyEquipmentList.flatMap(equipment => {
    return [equipment, ...(equipment.allSubcomponents || [])];
  });

// Calculate Total Evaluations using flattenedEquipments
const evaluationsCount = flattenedEquipments.reduce((acc, equipment) => {
  const validEvaluations = (equipment.evaluations || []).filter(evaluation => 
    isDateInRange(evaluation.started_at, startDate, endDate) || 
    isDateInRange(evaluation.ended_at, startDate, endDate)
  );
  return acc + validEvaluations.length;
}, 0);

  // Set the total evaluations state
  setTotalEvaluations(evaluationsCount);


  // Calculate the totalMsiInspections
  const totalMsiInspections = flattenedEquipments.reduce((acc, currEquipment) => {
    const equipmentCount = currEquipment.vals 
      ? Object.keys(_.groupBy(currEquipment.vals.edges, val => val.node && val.node.point_num)).length
      : 0;
    return acc + equipmentCount;
  }, 0);

  // Set the state
  setTotalMsiInspections(totalMsiInspections);
  }, [propertyEquipmentList, showMode, refreshCounter]);

  useEffect(() => {
    async function fetchEquipment() {
        try {
            let equipmentResults = await Equipment.filterBy([
                {"field": "property_id", "value": `${selectedProperty.id}`, "comparison": "="},
                {"field": "archived", "value": 0, "comparison": "="},
                lastSelectedEquipment ? {"field": "parent_key", "value": `${lastSelectedEquipment.key}`, "comparison": "="} : null,
            ].filter(x => x != null), [
                {"field": "recorded_at", "value": `${startDate || '2000-01-01'}`, "comparison": ">"},
                {"field": "recorded_at", "value": `${moment(endDate).add(1, 'days').format('YYYY-MM-DD')}`, "comparison": "<"},
            ], "name", "asc");

            let initialList = equipmentResults && (equipmentResults.data || []);
            let filteredList = initialList.filter(fig => {
                const componentPointSum = initialList.filter(x => x.parent_key === fig.key) 
                    .filter(c => c.lastValUploaded === null || moment(c.lastValUploaded.recorded_at).isBetween(moment(startDate), moment(endDate))) 
                    .reduce((acc, component) => acc + (component.vals && Object.keys(_.groupBy(component.vals.edges, val => val.node && val.node.point_num)).length), 0);

                const figPointLength = fig.vals && Object.keys(_.groupBy(fig.vals.edges, val => val.node && val.node.point_num)).length;
                const hasEvaluations = fig.evaluations && fig.evaluations.length > 0;
                const uniqueTaskUuidLength = fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length;

                return componentPointSum > 0 || figPointLength > 0 || hasEvaluations || uniqueTaskUuidLength > 0;
            });

            setIsFiltered(initialList.length !== filteredList.length && showMode === 'Showing Inspected');
        } catch (error) {
            console.error("Failed to fetch equipment:", error);
        }
    }

    fetchEquipment();
  }, [startDate, endDate, selectedProperty, lastSelectedEquipment, showMode]);


const loadTotalChecks = async () => {
  let totalCount = 0;

  for (const equipment of propertyEquipmentList) {
    if (equipment.checks && equipment.checks.edges) {
      // Group checks by 'task_uuid' and count unique task UUIDs
      const uniqueTaskUUIDCount = Object.keys(
        _.groupBy(equipment.checks.edges, check => check.node && check.node.task_uuid)
      ).length;

      // Add the count for this equipment to the total count
      totalCount += uniqueTaskUUIDCount;
    }
  }

  setTotalChecks(totalCount);
};




useEffect(() => {
  loadTotalChecks();
}, [propertyEquipmentList, startDate, endDate, showMode]);

  const selectEquipment = (equipment, isRefresh = false) => {
    setSelectedPoint(null);
    if (isRefresh) {
      setSelectedRootEquipment(equipment);
    } else {
      if (selectedRootEquipment) {
        setSelectedSubcomponentList([...selectedSubcomponentList, equipment]);
      } else {
        setSelectedRootEquipment(equipment);
      }
    }
  };
  

  const selectSubComponent = (subcomponent, parentEquipment) => {
    setSelectedPoint(null);
    if(selectedRootEquipment){
      setSelectedSubcomponentList([...selectedSubcomponentList, parentEquipment, subcomponent]);
    }else{
      setSelectedSubcomponentList([...selectedSubcomponentList, subcomponent]);
      setSelectedRootEquipment(parentEquipment)
    }
  }

  const selectPoint = (point, equipment) => {
    if (equipment){
      if(selectedRootEquipment){
        setSelectedSubcomponentList([...selectedSubcomponentList, equipment]);
      }else{
        setSelectedRootEquipment(equipment)
      }
    }
    setSelectedPoint(point);
  }

  const selectSheet = (sheet) => {
    setSelectedTask(null);
    setSelectedSection(null);
    setSelectedSheet(sheet);
  }

  const selectSection = (section) => {
    setSelectedTask(null);
    setSelectedSection(section);
  }

  const selectTask = (task) => {
    setSelectedTask(task);
  }

  const selectCheck = (check) => {
    setSelectedCheck(check);
  }

  const goBack = () => {
    if(showEquipmentDuplicateScreen){
      setShowEquipmentDuplicateScreen(false);
    }
    if(showPropertyTable){
      setShowPropertyTable(false); 
    }
    if(showEvaluationTable){
      setShowEvaluationTable(false);
    }
    if(showPointTable){
      setShowPointTable(false);
    }
    if(showComponents){
      setShowComponents(false);
    }
    if (selectedPoint) {
      setSelectedPoint(null);
    } else if (selectedSubcomponentList.length > 0) {
      setSelectedSubcomponentList([...selectedSubcomponentList.slice(0, -1)])
    } else {
      setSelectedRootEquipment(null)
    }
  }

  const refreshEquipment = async () => {
    const {data} = await Equipment.filterBy([{ 
      value: lastSelectedEquipment.uuid,
      field: 'uuid'
    }], [], 'updated_at', 'desc', 1, undefined, undefined, undefined, true);
    const latestEquipment = data.length > 0 ? data[0] : null;
    selectEquipment(latestEquipment);
  }

  const refreshEquipmentOnly = async () => {
    const {data} = await Equipment.filterBy([{ 
      value: lastSelectedEquipment.uuid,
      field: 'uuid'
    }], [], 'updated_at', 'desc', 1, undefined, undefined, undefined, true);
    const latestEquipment = data.length > 0 ? data[0] : null;
    selectEquipment(latestEquipment, true);
  }

  const handleWizardClick = async (event) => {
    event.stopPropagation(); // Prevent the li onClick from firing
    setLoading(true);
    setIsLoadingMPe(true);
  
    const propertyUUID = selectedProperty.uuid;
    const userId = user.id;
  
    // Collect all equipment UUIDs
    const equipmentUUIDs = propertyEquipmentList.flatMap(equipment => {
      const uuids = [equipment.uuid];
      if (equipment.allSubcomponents && equipment.allSubcomponents.length > 0) {
        const subcomponentUUIDs = equipment.allSubcomponents.map(subcomponent => subcomponent.uuid);
        uuids.push(...subcomponentUUIDs);
      }
      return uuids;
    });
  
    console.log("Selected property and start/end date and user", propertyUUID, startDate, endDate, userId);
  
    toast.success('MPe Started', {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'bold',
      },
    });
  
    try {
      const result = await MultipointEvaluation.createMultipointFromEquipment(equipmentUUIDs, startDate, endDate, userId, true);
  
      toast.success('Multipoint Evaluation has Finished', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {
          background: '#4caf50',
          color: '#fff',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      });
    } catch (error) {
      console.error('Error in createMultipointFromEquipment:', error);
    } finally {
      setLoading(false);
      setIsLoadingMPe(false);
      setShowMPeAlreadyRunButton(true);
      setRefreshCounter(prevCounter => prevCounter + 1); // Increment refreshCounter to trigger re-render

    }
  };
  const generateHash = async (inspectionUuidList) => {
    const sortedData = (inspectionUuidList || []).map(uuid => ({ uuid: uuid })).sort((a, b) => {
      const aStr = JSON.stringify(a);
      const bStr = JSON.stringify(b);
      return aStr.localeCompare(bStr, undefined, { numeric: true, sensitivity: 'base' });
    });
    const jsonString = JSON.stringify(sortedData);
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };
  
  const filterVals = (vals) => {
    return vals.filter(val => {
      const createdAtDate = moment(val?.recorded_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      return moment(createdAtDate).isBetween(moment(startDate), moment(endDate), undefined, '[]');
    });
  }
  

  const getInspectionsForEquipment = async (equipmentToFetch, propertyUUIDs) => {
    if (Utils.isBlank(propertyUUIDs) || propertyUUIDs.length === 0) {
      return [];
    }
    let selectedPointNumbers = null;
  
  
    const newUploads = await Val.filterBy([
      { field: 'archived', value: 0, comparison: "=" },
      propertyUUIDs ? { field: "properties.uuid", value: _.first(propertyUUIDs), comparison: "=" } : null,
      equipmentToFetch ? { field: 'fig_id', value: equipmentToFetch.id, comparison: "=" } : null,
      selectedPointNumbers ? { field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "=" } : null,
    ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true);
  
    let uploadList = newUploads.data || [];
  
    uploadList = uploadList.map(upload => {
      return {
        ...upload,
        sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
        parent_fig_key: upload?.point_info?.parent_fig_key,
        childSort: upload?.point_info?.parent_fig_key === null ? upload?.point_info?.parent_fig_key - 1 : upload?.point_info?.parent_fig_key
      };
    });
  
  
  
    uploadList = _.orderBy(uploadList, [
      upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
      upload => upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key,
    ], ['desc', 'desc', 'desc']);
  
   
    if(equipmentToFetch !== undefined){
      uploadList = _.flatten(
        Object.values(
          _.groupBy(uploadList, row => extractPointName(row)
        )
      )
      // sort by 
      .map(x => _.first(x)))
      .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx)
    }
  
    return uploadList;
  };

  const extractPointName = row => row.point_info ? row.point_info.measurement_name : "";

  const checkValidMultipointForAll = async () => {
    setIsLoadingMPe(true);

    let allAlreadyRun = true;
    let hasValidMPe = false;

  
    for (const equipment of propertyEquipmentList) {
      const equipmentUUIDs = [equipment.uuid];
      if (equipment.allSubcomponents && equipment.allSubcomponents.length > 0) {
        const subcomponentUUIDs = equipment.allSubcomponents.map(subcomponent => subcomponent.uuid);
        equipmentUUIDs.push(...subcomponentUUIDs);
      }
  
      const validityResponse = await MultipointEvaluation.validMultipointFromEquipment(equipmentUUIDs, startDate, endDate);
      const equipmentValidity = validityResponse?.equipmentValidity;
  
      for (const eq of [equipment, ...equipment.allSubcomponents]) {
        const eqValidity = equipmentValidity?.find(item => item.equipment_uuid === eq.uuid);
        if (eqValidity && eqValidity.isValid) {
          const inspections = await getInspectionsForEquipment(eq, [selectedProperty?.uuid]);
          const filteredInspections = filterVals(inspections);
  
          const inspectionUuidList = filteredInspections.map(inspection => inspection.uuid);
          const currentHash = await generateHash(inspectionUuidList);
          const recentEvaluation = eq.evaluations && eq.evaluations.find(evaluation => evaluation.recorded_yhat_json !== null);
  
          if (!recentEvaluation || recentEvaluation?.hash !== currentHash) {
            // console.log("1", recentEvaluation, recentEvaluation.hash, currentHash);
            hasValidMPe = true;
            setIsValidMultipoint(true);
            setShowMPeAlreadyRunButton(false);
            allAlreadyRun = false;
            break;
          }
        }
      }
    }
  
    if (allAlreadyRun) {
      console.log("2");
      setIsValidMultipoint(false);
      setShowMPeAlreadyRunButton(true);
    } else {
      setShowMPeAlreadyRunButton(false);
    }
    setIsLoadingMPe(false);

  };

  // useEffect(() => {
  //   checkValidMultipointForAll();
  // }, [propertyEquipmentList, startDate, endDate]);
  return (<>
    <EditModal options={editModal}/>
    <SendReportModal options={sendReportModal}/>
    <PropertyEditModal options={editPropertyModal}/>
    <EquipmentEditModal options={editEquipmentModal}/>
    <EvaluationEditModal refresh={refreshEquipmentOnly} onEvaluationCreated={handleEvaluationCreated} options={editEvaluationModal}/>
    {loading && <ImSpinner3 className="icon-spin" style={{fontSize: 25}}/>}
    {user && selectedProperty && !loading &&
        <div className="d-flex flex-column" style={{ position: 'sticky', top: '-.25em', zIndex: 100, backgroundColor: 'white' }}>
          <div className={`d-flex flex-row flex-no-wrap justify-space-between align-items-center mb-1`}>
            <Button variant="link" onClick={() => {
              if (showEquipmentAddScreen){
                setShowEquipmentAddScreen(false);
                return;
              }
              setSelectedRoutine(null)
              setSelectedProperty(null)
              if(window.location.href.split('/').slice(3,4)[0] === 'inspections'){
                history.replace(`/${window.location.href.split('/').slice(3,4)[0]}/properties`)
                return; 
              }
              history.replace(`/${window.location.href.split('/').slice(3,4)[0]}`)
              //Force Refresh: window.location = window.location.href.split('/').slice(3, 4).join('/')
            }}>
              <MdArrowBack />
            </Button>
            
            <div className="d-flex flex-column flex-no-wrap flex-grow-1">
              <div className="d-flex flex-row flex-no-wrap justify-space-between align-items-center">
                <div className="font-weight-bold flex-grow-1 justify-self-start">{selectedProperty.name}</div>
              </div>
            <div className="d-flex flex-row w-100 justify-content-between align-items-center" style={{maxHeight: '40px'}}>

              {/* TIME AND Routine */}
              <div className="d-flex flex-row flex-no-wrap justify-content-start align-items-center">
              {!hideDateSelector && (!hideStartDate || !hideEndDate) && <>
                <span className="font-weight-bold align-self-center">Date Range:</span>

                {!hideStartDate && <>
                  <DatePicker onChange={(value) => {
                    const startDate = moment(value)
                    const newStartDate = startDate.endOf("day").format("YYYY-MM-DD");
                    setStartDate(newStartDate)
                  }} value={moment(startDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null}/>
                </>}

                {!hideStartDate && !hideEndDate && <>
                  <span>&nbsp;to&nbsp;&nbsp;</span>
                </>}

                {!hideEndDate && <>
                  <DatePicker onChange={(value) => {
                    const endDate = moment(value)
                    const newDate = endDate.endOf("day").format("YYYY-MM-DD");
                    setEndDate(newDate)
                  }} value={moment(endDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null}/>
                </>}
              </>}


              <span className="font-weight-bold">Routine: </span> 
              <Dropdown>
            <Dropdown.Toggle variant="outline" id="dropdown-basic">
              {selectedRoutine && selectedRoutine.name || "None Selected"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <Dropdown.Item onClick={() => setSelectedRoutine(null)}>None Selected</Dropdown.Item>
              {selectedProperty.routines && selectedProperty.routines.map(routine => (
                <Dropdown.Item key={routine.uuid} onClick={() => setSelectedRoutine(routine)}>
                  {routine && routine.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
            </Dropdown>


             


              <div>
                {selectedRoutine && <span className="">
                  <span className="font-weight-bold ml-3 mr-2">Completion: </span>
                  {selectedRoutine.valsFilter && selectedRoutine.valsFilter.edges && Object.values(_.groupBy(selectedRoutine.valsFilter.edges.filter(x => moment(x.node.recorded_at).isAfter(moment(startDate))), x => x.node.point_num)).map(x => _.first(x)).length || 0}
                  &nbsp;/&nbsp;
                  {selectedRoutine.routinePoints.length}
                </span>}
              </div>
              </div>
              
              {/* BUTTONS */}

         


              <div className="d-flex flex-row flex-no-wrap justify-content-end align-items-center">

              {sortByInspections && !showPropertyTable && !showEvaluationTable && !showPointTable && !selectedSheet && selectedProperty && !selectedPoint && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && 
                  <button 
                      type="button" 
                      className="btn btn-outline-secondary btn-sm mr-1"
                      style={{
                          backgroundColor: isFiltered ? '#f9d835' : '', // Conditional background color
                          color: isFiltered ? 'black' : '', // Conditional text color
                          borderColor: isFiltered ? '#f9d835' : '' // Conditional border color
                      }}
                      onClick={() => {
                          setShowMode(prevMode => prevMode === 'Showing Inspected' ? 'Showing All' : 'Showing Inspected');
                      }}
                  >
                      {showMode}
                  </button>
            }


              { !showEquipmentDuplicateScreen &&
                <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={async () => {
                  handleEvaluationCreated();
                  setRefreshCounter(prevCounter => prevCounter + 1);
                }}>
                  <FcRefresh style={{fontSize: 24}}/>
                  Refresh
                </a>
              } 

              {!hideLayoutButton && !showEquipmentDuplicateScreen &&
                <Link type="button" className="btn btn-outline-secondary btn-sm mr-1 " to={`${backPath}/table/${propertyUUID}/`}>
                  <FcGrid style={{fontSize: 24}}/>
                  Layout
                </Link>
              } 

             

              {showEquipmentAdd && !showEquipmentDuplicateScreen &&
                <a className={`btn btn-outline-secondary btn-sm mr-1 `} onClick={() => {   
                  setShowEquipmentAddScreen(true);
                  setParentEquipment(selectedRootEquipment ? lastSelectedEquipment : null);
                  setValue(null);
          
                }}>Add {selectedRootEquipment ? "Component" : "Equipment"}</a>
              }

              {showEquipmentEdit && selectedRootEquipment && !showEquipmentDuplicateScreen &&
                <a className={`btn mr-1 btn-sm  btn-outline-secondary`} onClick={() => {
                  setShowComponents(false);
                  setShowPointTable(false);
                  setShowPropertyTable(false);
                  setShowEvaluationTable(false);
                  setShowEquipmentAddScreen(true);
                  setParentEquipment((selectedRootEquipment || []).length >= 1 ? lastSelectedEquipment : null)
                  setValue(lastSelectedEquipment.uuid);
          
                }}>Edit {(selectedSubcomponentList || []).length >= 1 ? "Component" : "Equipment"}</a>
              } 

              {showEquipmentEdit && selectedRootEquipment && !showEquipmentDuplicateScreen &&
                <a className={`btn mr-1 btn-sm  btn-outline-secondary`} onClick={() => {
                  setShowComponents(false);
                  setShowPointTable(false);
                  setShowPropertyTable(false);
                  setShowEvaluationTable(false);
                  setShowEquipmentDuplicateScreen(true);
                  setParentEquipment((selectedRootEquipment || []).length >= 1 ? lastSelectedEquipment : null)
                  setValue(lastSelectedEquipment.uuid);
          
                }}>Duplicate {(selectedSubcomponentList || []).length >= 1 ? "Component" : "Equipment"}</a>
              } 

              {!selectedSheet && selectedProperty && selectedRootEquipment && !selectedPoint && !showEquipmentDuplicateScreen &&
                
                <a className={`btn btn-outline-secondary btn-sm mr-1 `} onClick={() => {
                  setEditEvaluationModal({
                    onHide: () => {
                      setEditEvaluationModal({})
          
                    },
                    selectedEquipment:  lastSelectedEquipment,
                    title: `${lastSelectedEquipment.name}`
                  });
                }}>Add Evaluation</a>

              }
    
            

              {showPropertyEdit && selectedProperty && !selectedRootEquipment &&
                <a className={`btn btn-outline-secondary btn-sm mr-1 `} onClick={() => {
                  setEditPropertyModal({
                    onHide: () => {
                      setEditPropertyModal({})
                      fetchProperty()
                    },
                    value:  selectedProperty.uuid,
                    title: "Edit Property"
                  });
                }}>Edit Property</a>
              } 

              {setSendReportModal && showSendReport &&
                <a className={`btn btn-outline-secondary btn-sm mr-1 `} onClick={() => {
                    sendReport(propertyUUID);
                  }}>
                  <div className="d-flex flex-row text-left">
                    <FcDocument style={{fontSize: 24}}/>
                    <div className="pl-2">Send Report</div>
                  </div>
                </a>
              }
              </div>

            </div>
            </div>
          </div>
        </div>
      }

    {selectedProperty && !selectedSheet && (selectedPoint || selectedRootEquipment) && !loading &&
      <div className="d-flex flex-column" style={{ position: 'sticky', top: '3.5em', zIndex: 99, backgroundColor: 'white' }}>
        <div className={`d-flex flex-row flex-no-wrap justify-start align-items-center`}>
          <Button variant="link" onClick={() => {
          if(showEquipmentAddScreen){
            setShowEquipmentAddScreen(false);
            return;
          } 
          if(showEquipmentDuplicateScreen){
            setShowEquipmentDuplicateScreen(false);
            return;
          } 

          goBack();
          }}>
            <MdArrowBack />
          </Button>
          {selectedRootEquipment && 
            <div className="font-weight-bold">{selectedRootEquipment.name}</div>
          }
          {selectedSubcomponentList.map(sub => {
            return <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{sub && sub.name}</div>

          })}
          {selectedPoint &&
            <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{selectedPoint.measurement.name}</div>

          }
        </div>
        <div></div>
      </div>
    }

    {selectedSheet && !loading &&
      <div className="d-flex flex-column" style={{ position: 'sticky', top: '3.55em', zIndex: 99, backgroundColor: 'white' }}>
        <div className={`d-flex flex-row flex-no-wrap justify-start align-items-center`} >
          <Button variant="link" onClick={() => {
            if (selectedTask) {
              setSelectedTask(null);
              setSelectedCheck(null);
            } else if (selectedSection) {
              setSelectedSection(null)

            } else {
              setSelectedSheet(null)

            }
          }}>
            <MdArrowBack />
          </Button>
          {selectedRootEquipment && 
            <div className="font-weight-bold">{selectedRootEquipment.name}</div>
          }
          {selectedSubcomponentList.map(sub => {
            return <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{sub && sub.name}</div>
          })}
          {selectedSheet && 
            <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{selectedSheet.name}</div>
          }
          {selectedSection &&
            <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{selectedSection.name}</div>
          }
          {selectedTask &&
            <div className="font-weight-bold">&nbsp;&nbsp;/&nbsp;&nbsp;{selectedTask.name}</div>
          }
        </div>
        <div></div>
      </div>
    }

    

    {!showPropertyTable && !showEvaluationTable && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && !selectedSheet && selectedProperty && selectedRootEquipment && !EquipmentEditComponent &&
      <ul className="d-flex flex-row ml-1 mt-2"  style={{minWidth: '10em'}}>
        <div className="border p-3" ><span className="font-weight-bold">Nameplate:</span> {lastSelectedEquipment.faceplate_image ? "Yes" : "No"}</div>
        <div className="border p-3" ><span className="font-weight-bold">Make:</span> {lastSelectedEquipment.make || "Blank"}</div>
        <div className="border p-3" ><span className="font-weight-bold">Model:</span> {lastSelectedEquipment.model_num || "Blank"}</div>
        <div className="border p-3" ><span className="font-weight-bold">Serial:</span> {lastSelectedEquipment.serial_num || "Blank"}</div>
        <div className="border p-3" ><span className="font-weight-bold">Year:</span> {lastSelectedEquipment.year || "Blank"}</div>
        <div className="border p-3" ><span className="font-weight-bold">Criticality:</span> {lastSelectedEquipment.criticality || "Blank"}</div>
        {lastSelectedEquipment.system && <div className="border p-3" ><span className="font-weight-bold">System:</span> {lastSelectedEquipment.system.name || "Blank"}</div>}
        <div className="border p-3" ><span className="font-weight-bold">Notes:</span> {lastSelectedEquipment.notes || "Blank"}</div>
      </ul>
    }

  
    {!selectedSheet && selectedProperty && EquipmentAddComponent && showEquipmentAddScreen && <>
      <EquipmentAddComponent 
          selectedEquipment={lastSelectedEquipment ? lastSelectedEquipment : null}
          parentEquipment={parentEquipment}
          uuid={value}
          refreshKey={refreshKey}
          selectedProperty={selectedProperty}
          goBackFn={() => {
            if (showEquipmentAddScreen){
              setRefreshKey(prevKey => prevKey + 1);
              setShowEquipmentAddScreen(false);
              return;
            }
            setSelectedRoutine(null)
            setSelectedProperty(null)
            history.replace(`/${window.location.href.split('/').slice(3,4)[0]}`)
          }}
          duplicate={false}

        />
    </>}


    {!selectedSheet && selectedProperty && EquipmentAddComponent && showEquipmentDuplicateScreen && <>
      

        <EquipmentDuplicateComponent 
          selectedEquipment={{uuid: lastSelectedEquipment.uuid, id: lastSelectedEquipment.id, key: lastSelectedEquipment.key}}
          selectedProperty={selectedProperty}
          goBackFn={goBack}
          setRefreshCounter={setRefreshCounter}
        />



    </>
    
    
    }


    {/* Components */}




<div className="ml-5 mt-3 mb-2">
  <div className="d-flex flex-row justify-content-start align-items-top flex-wrap">

  {!showComponents && !showPropertyTable && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && !selectedSheet && selectedProperty && selectedRootEquipment && !!EquipmentEditComponent && <>
      <PropertyTableCard 
          setShowPropertyTable={setShowPropertyTable}
          equipment={lastSelectedEquipment}
        />
    </>}

    {showPropertyTable && !!EquipmentEditComponent && <>
    <div>
    <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={() => {setShowPropertyTable(false);}} >Back To Cards</a>

      <EquipmentEditComponent 
          selectedEquipment={{uuid: lastSelectedEquipment.uuid, id: lastSelectedEquipment.id, key: lastSelectedEquipment.key}}
          selectedProperty={selectedProperty}
        />

        </div>
    </>}





    {!showPropertyTable && !showEvaluationTable && !showComponents && !showPointTable && !selectedSheet && selectedProperty && selectedRootEquipment && !selectedPoint && EvaluationTableComponent && 
      (lastSelectedEquipment.evaluations || []).length > 0 &&
          <EvaluationTableCard
          setShowEvaluationTable={setShowEvaluationTable}
          equipment={lastSelectedEquipment}
          />
    }

{showEvaluationTable && EvaluationTableComponent && 
      <div>
            {/* <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={() => {setShowEvaluationTable(false);}} >Back To Cards</a> */}

          <EvaluationTableComponent
            key={evaluationCreated}
            refreshCounter={refreshCounter}
            startDate={startDate}
            selectedEquipment={lastSelectedEquipment}
          />
          </div>
    }

  

{
  !showPropertyTable && !showEvaluationTable && !showComponents && !selectedSheet && selectedProperty && selectedRootEquipment && !selectedPoint && PointTableComponent && 
  (lastSelectedEquipment.points || []).filter(point => 
    selectedRoutine ? selectedRoutine.routinePoints.filter(rp => rp.point_uuid === point.uuid).length > 0 : true
  ).length > 0 && !showPointTable && valChecksCount !== 0 &&

  <PointTableCard
    refreshCounter={refreshCounter}
    startDate={startDate}
    equipment={lastSelectedEquipment}
    PointTableComponent={PointTableComponent}
    setShowPointTable={setShowPointTable}
  />

}



{!showEquipmentAddScreen && !showEquipmentDuplicateScreen && !showPropertyTable && !showEvaluationTable && !showComponents && !showPointTable && !selectedSheet && selectedProperty && selectedRootEquipment && !selectedPoint && 
      lastSelectedEquipment.sheets.length > 0 && taskNumber !== 0 &&
          <TaskingSheetCard 
            selectSheet={selectSheet} 
            lastSelectedEquipment={lastSelectedEquipment}
            hideValCheckNumerator={hideValCheckNumerator}
            hideValInspectionCountNumerator={hideValInspectionCountNumerator}
            hideInspectionCounts={hideValCheck}
            startDate={startDate}
            endDate={endDate}
          />
    }

    {
  !showPropertyTable && !showEvaluationTable && !showComponents && !selectedSheet && selectedProperty && selectedRootEquipment && !selectedPoint && PointTableComponent && valChecksCount !== 0 &&
  <div>
  <PointTableComponent 
    refreshCounter={refreshCounter}
    startDate={startDate} 
    selectedEquipment={lastSelectedEquipment}
  />

  </div>
} 

{
  selectedRootEquipment &&   !showPropertyTable && !showEvaluationTable && !showPointTable && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && !selectedSheet && selectedProperty && !selectedPoint && lastSelectedEquipment && lastSelectedEquipment.allSubcomponents && lastSelectedEquipment.allSubcomponents.length > 0 &&

  <>
    <div className="ml-2">
      <EquipmentGrid
          setShowComponents={setShowComponents} 
          sortByInspections={sortByInspections}
          sortByName={sortByName}
          sortByAnalysis={sortByAnalysis}
          hideSheetsSection={hideSheetsSection}
          startDate={startDate}
          endDate={endDate}
          property={selectedProperty}
          rootEquipmentOnly={false}
          routine={selectedRoutine}
          filterByParent={lastSelectedEquipment}
          lastSelectedEquipment={lastSelectedEquipment}
          lastModifiedEquipment={lastModifiedEquipment}
          hideAnalysisColors={hideAnalysisColors}
          hideExpandedPointAnalysisColors={hideExpandedPointAnalysisColors}
          subcomponentSelectFunction={selectSubComponent}
          equipmentSelectFunction={selectEquipment}
          pointSelectFunction={selectPoint}
          hideInspectionCounts={false}
          sheetSelectFunction={selectSheet}
          sectionSelectFunction={selectSection}
          taskSelectFunction={selectTask}
          hideValCheckNumerator={hideValCheckNumerator}
          hideValInspectionCountNumerator={hideValInspectionCountNumerator}
          isComponent={true}
          setEditEvaluationModal={setEditEvaluationModal}
          setShowEvaluationTable={setShowEvaluationTable}
          refresh={refreshCounter}
          showMode={showMode}
          setRefreshCounter={setRefreshCounter}

      />
    </div>
  </>
}


</div>
</div>

  {sortByInspections && !showPropertyTable && !showEvaluationTable && !showPointTable && !selectedSheet && selectedProperty && !selectedPoint && !selectedRootEquipment && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && 
  <div className="d-flex flex-row">
    <StatusBar title="MSi" count={totalMsiInspectionsState} color="#ffc233" />
    {loadingTask ? (
    <div className="d-flex align-items-center justify-content-center" style={{ margin: '0 12px' }}>
      <ImSpinner3 className="icon-spin" style={{ fontSize: 25, marginTop: 10 }} />
    </div>
  ) : (
    <StatusBar title="Tasks" count={totalChecks} color="#33ff41" />
  )}

{/* <Button
  type="button"
  className="btn btn-outline-secondary btn-sm mr-1"
  style={{ 
    color: 'white', 
    backgroundColor: showMPeAlreadyRunButton ? 'grey' : '#000', 
    borderColor: showMPeAlreadyRunButton ? 'grey' : '#000' 
  }}
  onClick={handleWizardClick}
  disabled={showMPeAlreadyRunButton}
>
  {isLoadingMPe ? <ImSpinner3 className="icon-spin" style={{ fontSize: 18 }} /> : 'MPe'}
</Button> */}
  </div>
}



    
  {!showPropertyTable && !showEvaluationTable && !showPointTable && !selectedSheet && selectedProperty && !selectedPoint && !selectedRootEquipment && !showEquipmentAddScreen && !showEquipmentDuplicateScreen && 
    <div className="ml-2">
      <EquipmentGrid
        sortByInspections={sortByInspections}
        sortByName={sortByName}
        sortByAnalysis={sortByAnalysis}
        hideSheetsSection={hideSheetsSection}
        startDate={startDate}
        endDate={endDate}
        routine={selectedRoutine}
        property={selectedProperty}
        lastModifiedEquipment={lastModifiedEquipment}
        lastSelectedEquipment={lastSelectedEquipment}
        rootEquipmentOnly={true}
        hideAnalysisColors={hideAnalysisColors}
        hideExpandedPointAnalysisColors={hideExpandedPointAnalysisColors}
        subcomponentSelectFunction={selectSubComponent}
        equipmentSelectFunction={selectEquipment}
        pointSelectFunction={selectPoint}
        hideInspectionCounts={false}
        sheetSelectFunction={selectSheet}
        sectionSelectFunction={selectSection}
        taskSelectFunction={selectTask}
        allowHidingComponentSection={allowHidingComponentSection}
        allowHidingPointsSection={allowHidingPointsSection}
        hideValCheckNumerator={hideValCheckNumerator}
        hideValInspectionCountNumerator={hideValInspectionCountNumerator}
        isInspectionPage={isInspectionPage}
        setShowPointTable={setShowPointTable}
        setShowComponents={setShowComponents}
        showMode={showMode}
        setEditEvaluationModal={setEditEvaluationModal}
        setShowEvaluationTable={setShowEvaluationTable}
        refresh={refreshCounter}
      />
    </div>
    }

    
    
    {selectedSheet && selectedSection && !selectedCheck && selectedSheet.tasks.filter(task => task.section_uuid === selectedSection.uuid).length > 0 && <>
      <div className="ml-2">
        <TaskGrid
          taskSelectFunction={selectTask}
          tasks={selectedSheet.tasks.filter(task => task.section_uuid === selectedSection.uuid)}
          checkSelectFunction={selectCheck}
        ></TaskGrid>
      </div>
    </>}

    {selectedSheet && !selectedSection && !selectedCheck && <>
      <div className="ml-2">
        <SectionTaskGrid
          sections={selectedSheet.sections}
          tasks={selectedSheet.tasks}
          startDate={startDate}
          endDate={endDate}
          equipment={lastSelectedEquipment}
        ></SectionTaskGrid>
      </div>
    </>}

    {selectedSheet && selectedCheck && <>
      <CheckTable selectedTask={selectedTask} selectedEquipment={lastSelectedEquipment}/>
    </>}

  </>)
}

export default InspectionEquipmentHierarchy;