import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { QueryProvider } from './context/QueryContext';
import { ProvideAuth, useAuth } from './context/AuthContext';
import SigninScreen from './screens/SigninScreen';
import AboutScreen from './screens/AboutScreen';
import ImportScreen from './screens/ImportScreen';
import BarcodeScreen from './screens/BarcodeScreen';
import UserEditScreen from './screens/user/UserEdit';
import Amplify from 'aws-amplify';
import AwsExports from './common/AwsExports.js';
import PrivateRoute from './routeUtilities/PrivateRoute';
import PropertyEditScreen from './screens/property/PropertyEdit';
import EquipmentEditScreen from './screens/equipment/EquipmentEdit';
import StartScreen from './screens/StartScreen';
import DSTAppInBrowserScreen from './screens/DSTAppInBrowserScreen';
import UsersScreen from './screens/UsersScreen';
import InspectionsScreen from './screens/InspectionsScreen';
import AnalyticsScreen from './screens/AnalyticsScreen';
import MigrationScreen from './screens/MigrationScreen';
import MetabaseScreen from './screens/MetabaseScreen';
import AnalyticsCountScreen from './screens/AnalyticsCountScreen'
import SoundDatabaseScreen from './screens/SoundDatabaseScreen'

import PropertiesScreen from './screens/PropertiesScreen'
import ReportsScreen from './screens/ReportsScreen';
import MetricsScreen from './screens/MetricsScreen';
import CompanyActivityReport from './screens/CompanyActivityReport';
import { ToastContainer } from 'react-toastify';
import {TopNavBar} from './screens/components/TopNavBar';
import TaskingSheetScreen from './screens/TaskingSheetScreen';
import { ProvideFilterContext, useFilterContext } from "./context/SharedFilterContext";
import 'bootstrap/dist/css/bootstrap.min.css';


Amplify.configure(AwsExports);

if (window.location.href.includes("web.dstapp.com")){
  // Production build, hide all logging statements
  console.log = () => {}
}

function App() {
  return (
    <QueryProvider>
      <ProvideAuth>
        <ProvideFilterContext>
          <Router>
            <ToastContainer
              autoClose={20000}
              limit={5}
              position={"bottom-right"}
              newestOnTop
              closeOnClick
              draggable
            />
            <div className="d-flex flex-column container-fluid p-0 m-0" style={{minHeight: "100vh", backgroundColor: "#e6e6e6"}}>
              <TopNavBar/>

              {/* Routes match BEGINNING of URL, order matters!*/}
              <Switch>
                <Route path="/auth">
                  <SigninScreen />
                </Route> 
                {/* <PrivateRoute path="/propertySelect">
                  <PropertiesPickerScreen />
                </PrivateRoute> */}
                <PrivateRoute path="/start">
                <InspectionsScreen />
                </PrivateRoute>
                <PrivateRoute path="/user/edit/:uuid">
                  <UserEditScreen></UserEditScreen>
                </PrivateRoute>
                <PrivateRoute path="/user/new">
                  <UserEditScreen></UserEditScreen>
                </PrivateRoute>
                <PrivateRoute path="/property/edit/:uuid">
                  <PropertyEditScreen></PropertyEditScreen>
                </PrivateRoute>
                <PrivateRoute path="/property/edit/">
                  <PropertyEditScreen></PropertyEditScreen>
                </PrivateRoute>
                <PrivateRoute path="/equipment/edit/:uuid">
                  <EquipmentEditScreen></EquipmentEditScreen>
                </PrivateRoute>
                <Route path="/generate-barcode">
                  <BarcodeScreen />
                </Route>
                <PrivateRoute path="/about">
                  <AboutScreen />
                </PrivateRoute>
                <PrivateRoute path="/import">
                  <ImportScreen />
                </PrivateRoute>
                <PrivateRoute path="/users">
                  <UsersScreen />
                </PrivateRoute>
                <Route path="/reports">
                  <ReportsScreen />
                </Route>
                <PrivateRoute path="/properties">
                  <PropertiesScreen />
                </PrivateRoute>
                <PrivateRoute path="/tasking-sheets">
                  <TaskingSheetScreen/>
                </PrivateRoute>
                <PrivateRoute path="/inspections">
                  <InspectionsScreen />
                </PrivateRoute>
                <PrivateRoute path="/analytics">
                  <AnalyticsScreen />
                </PrivateRoute>
                <PrivateRoute path="/migration">
                  <MigrationScreen />
                </PrivateRoute>

                <PrivateRoute path="/metabase_screen">
                  <MetabaseScreen />
                </PrivateRoute>

                <PrivateRoute path="/metrics_screen">
                  <MetricsScreen />
                </PrivateRoute>

                <PrivateRoute path="/company_activity_report">
                  <CompanyActivityReport />
                </PrivateRoute>


                <PrivateRoute path="/analytics_count_screen">
                  <AnalyticsCountScreen />
                </PrivateRoute>


                <PrivateRoute path="/sound_database_screen">
                  <SoundDatabaseScreen />
                </PrivateRoute>

                <PrivateRoute path="/dstapp">
                  <DSTAppInBrowserScreen/>
                </PrivateRoute>
                <PrivateRoute path="/">
                  {/* <StartScreen /> */}
                  <InspectionsScreen />
                </PrivateRoute>
              </Switch>

              <footer className="footer mt-auto">
                  <div className="d-flex flex-row justify-content-end">
                    <img
                      src="/19286M1a_PowBy_DST_LogoColor.png"
                      style={{height: 50}}
                      className="m-2"
                    />
                  </div>
              </footer>

            </div>
          </Router>
        </ProvideFilterContext>
      </ProvideAuth>
    </QueryProvider>
  );
}

export default App;
