import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { NavSidebar } from './components/NavSidebar';
import { useAuth } from '../context/AuthContext';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import User from '../models/User';
import ActionLog from '../models/ActionLog';

import Company from '../models/Company';
import Property from '../models/Property';
import Equipment from '../models/Equipment';
import _ from 'lodash';
import DataTable from 'react-data-table-component';

const CompanyActivityReport = () => {
  const {
    user,
    isAdminOrHigher
  } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [uniqueMsiCount, setUniqueMsiCount] = useState(0);
  const [reportCount, setReportCount] = useState(0);
  const [filteredPropertyActivityHistory, setFilteredPropertyActivityHistory] = useState([]);
  const [propertiesInspectedCount, setPropertiesInspectedCount] = useState(0);
  const [criticalRedPoints, setCriticalRedPoints] = useState(0);
  const [pointsInspected, setPointsInspected] = useState('0/0 (0%)');

  // State for date range
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('day').toDate());
  const [endDate, setEndDate] = useState(moment().startOf('day').toDate());

  // Temporary state for date range
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const [isPropertiesExpanded, setIsPropertiesExpanded] = useState(false);
  const [inspectedPropertiesDetails, setInspectedPropertiesDetails] = useState([]);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);

  const [isUsersExpanded, setIsUsersExpanded] = useState(false);
  const [activeUsersDetails, setActiveUsersDetails] = useState([]);
  const [isMsiExpanded, setIsMsiExpanded] = useState(false);
  const [msiDetails, setMsiDetails] = useState([]);

  const [isReportsExpanded, setIsReportsExpanded] = useState(false);
  const [reportDetails, setReportDetails] = useState([]);
  const [reportContextDetails, setReportContextDetails] = useState([]);

  const [shouldFetch, setShouldFetch] = useState(true);

  const fetchActiveUsers = async () => {
    if (!user || !user.company_id) {
      console.log("User or company ID is not available.");
      return;
    }

    // Fetch users for the current company that are not archived
    const filters = [
      {"field": "company_id", "value": `${user.company_id}`, "comparison": "="},
      {"field": "archived", "value": "false", "comparison": "="}
    ];

    try {
      const results = await User.filterBy(filters, "last_login", "desc");
      const users = results.data;
      console.log("This is all the users before we filter", users);

      // Filter users by last_login date within the selected date range
      // Exclude those with emails ending in "discoverdst.com" and "johnjdst@me.com"
      const activeUsers = users.filter(user => {
        const lastLoginDate = moment(user.last_login).startOf('day');
        const isActive = lastLoginDate.isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'), null, '[]');
        const isNotCompanyEmail = !user.email.endsWith("discoverdst.com");
        const isNotJohnJdst = user.email !== "johnjdst@me.com";
        return isActive && isNotCompanyEmail && isNotJohnJdst;
      });

      console.log("This is the active users", activeUsers); 

      setActiveUsersDetails(activeUsers.map(user => user.full_name)); // Store full names
      setActiveUsersCount(activeUsers.length);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
    }
  };

  const fetchUniqueMsiCount = async () => {
    if (!user || !user.company_id) {
      console.log("User or company ID is not available.");
      return;
    }

    console.log("Fetching unique MSI devices...");

    // Fetch all users for the current company
    const userFilters = [
      {"field": "company_id", "value": `${user.company_id}`, "comparison": "="},
      {"field": "archived", "value": "false", "comparison": "="}
    ];

    try {
      const userResults = await User.filterBy(userFilters, "last_login", "desc");
      const companyUsers = userResults.data;

      // Exclude users with emails ending in "discoverdst.com" and "johnjdst@me.com"
      const filteredCompanyUsers = companyUsers.filter(user => {
        const isNotCompanyEmail = !user.email.endsWith("discoverdst.com");
        const isNotJohnJdst = user.email !== "johnjdst@me.com";
        return isNotCompanyEmail && isNotJohnJdst;
      });

      const companyUserUUIDs = filteredCompanyUsers.map(user => user.uuid);
      const userMap = new Map(filteredCompanyUsers.map(user => [user.uuid, user.full_name]));

      // Fetch action logs for action_type_id 1 (MSI connected) within the date range
      const logFilters = [
        {"field": "action_type_id", "value": 1, "comparison": "="},
        {"field": "created_at", "value": startDate.toISOString(), "comparison": ">="},
        {"field": "created_at", "value": endDate.toISOString(), "comparison": "<="}
      ];

      const logResults = await ActionLog.filterBy(logFilters, "created_at", "desc", 10000);
      const logs = logResults.data;

      // Filter logs by checking if the user_uuid is in the list of filtered company user UUIDs
      const companyLogs = logs.filter(log => {
        const logDate = moment(log.created_at).startOf('day');
        return companyUserUUIDs.includes(log.user_uuid) && logDate.isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'), null, '[]');
      });

      // Extract unique MSI serial numbers and associated user names
      const uniqueMsiDetails = new Map();
      companyLogs.forEach(log => {
        if (!uniqueMsiDetails.has(log.msi_serial)) {
          uniqueMsiDetails.set(log.msi_serial, userMap.get(log.user_uuid));
        }
      });

      setMsiDetails(Array.from(uniqueMsiDetails.entries())); // Store unique MSI serial numbers with user names
      setUniqueMsiCount(uniqueMsiDetails.size);

      console.log("Unique MSI serial numbers with users:", Array.from(uniqueMsiDetails.entries()));
    } catch (error) {
      console.error("Error fetching action logs or users:", error);
    }
  };

  const fetchReportCount = async () => {
    if (!user || !user.company_id) {
      console.log("User or company ID is not available.");
      return;
    }

    const filters = [
      {"field": "company_uuid", "value": `${user.company.uuid}`, "comparison": "="},
      {"field": "action_type_id", "value": 3, "comparison": "="}, // Action type for sent report
      {"field": "created_at", "value": startDate.toISOString(), "comparison": ">="},
      {"field": "created_at", "value": endDate.toISOString(), "comparison": "<="}
    ];

    try {
      const results = await ActionLog.filterBy(filters, "created_at", "desc", 1000);
      const logs = results.data;

      const uniqueReportsMap = new Map();

      logs.forEach(log => {
        try {
          const context = JSON.parse(log.context);
          const reportTypeName = context.itemReportInput.reportTypeName;
          const propertyUUID = log.property_uuid;
          const reportDate = new Date(log.created_at).toISOString().split('T')[0];
          const reportMoment = moment(reportDate).startOf('day');

          if (reportMoment.isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'), null, '[]')) {
            const reportIdentifier = `${reportDate}-${propertyUUID}-${reportTypeName}`;
            
            if (!uniqueReportsMap.has(reportIdentifier)) {
              // Replace the domain in the report link and add the /dst-reports path
              const originalLink = context.reportS3Link || 'No link available';
              const updatedLink = originalLink.replace('dst-reports.s3.us-west-2.amazonaws.com', 'mv.dstapp.com/dst-reports');

              uniqueReportsMap.set(reportIdentifier, {
                link: updatedLink, // Use the updated link
                reportType: reportTypeName || 'N/A',
                createdAt: log.created_at || 'N/A'
              });
            }
          }
        } catch (error) {
          console.error("Error parsing context or extracting report details:", error);
        }
      });

      setReportDetails(Array.from(uniqueReportsMap.values()));
      setReportCount(uniqueReportsMap.size);
    } catch (error) {
      console.error("Error fetching report logs:", error);
    }
  };

  const fetchFilteredPropertyActivityHistory = async () => {
    if (!user || !user.company_id) {
      console.log("User or company ID is not available.");
      return;
    }

    try {
      console.log("Starting fetchFilteredPropertyActivityHistory...");
      
      // Step 1: Fetch Property UUIDs
      console.log("Fetching Property UUIDs...");
      const propertiesUuidInCompany = await Company.detailedFilteredPropertyUuidActivity(
        user.id,
        user.company.uuid,
        null,
        null, // No propertyNameFilter
        startDate,
        endDate
      );
      console.log("Fetched Property UUIDs:", propertiesUuidInCompany);

      const sortedProperties = propertiesUuidInCompany?.data?.flatMap(company => company?.propertiesFilter)
        .sort((a, b) => moment(b.last_upload_at).diff(moment(a.last_upload_at)));
      const propertyUUIDs = sortedProperties?.map(property => property?.uuid);
      console.log("Sorted Property UUIDs:", propertyUUIDs);

      // Step 2: Fetch Property Data in Batches in Parallel
      const batchSize = 10;
      const batchPromises = [];
      for (let i = 0; i < propertyUUIDs.length; i += batchSize) {
        const batchUUIDs = propertyUUIDs.slice(i, i + batchSize);
        console.log(`Preparing batch ${i / batchSize + 1}:`, batchUUIDs);
        batchPromises.push(Property.getCardData(batchUUIDs, user.id, startDate, endDate));
      }

      // Execute all batch requests in parallel
      const allPropertyDataBatches = await Promise.all(batchPromises);
      const allPropertyData = allPropertyDataBatches.flat();
      console.log("All Fetched Property Data:", allPropertyData);

      // Step 3: Filter and Sort Data
      const startMoment = moment(startDate).startOf('day');
      const endMoment = moment(endDate).endOf('day');

      const filteredData = allPropertyData.filter(row => {
        const recordedAtDate = row?.vals?.edges[0]?.node?.recorded_at || '';
        const createdAtDate = row?.checks?.edges[0]?.node?.created_at || '';

        const recordedAtMoment = moment(recordedAtDate).startOf('day');
        const createdAtMoment = moment(createdAtDate).startOf('day');
        const momentsArray = [recordedAtMoment, createdAtMoment].filter(m => m.isValid());
        if (momentsArray.length === 0) return false;

        const mostRecentMoment = moment.max(...momentsArray);
        return mostRecentMoment.isBetween(startMoment, endMoment, null, '[]');
      }).sort((a, b) => {
        const momentsA = [
          moment(a?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(a?.checks?.edges[0]?.node?.created_at || '').startOf('day')
        ].filter(m => m.isValid());

        const momentsB = [
          moment(b?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(b?.checks?.edges[0]?.node?.created_at || '').startOf('day')
        ].filter(m => m.isValid());

        const mostRecentDateA = moment.max(...momentsA);
        const mostRecentDateB = moment.max(...momentsB);

        return mostRecentDateB.diff(mostRecentDateA);
      });

      console.log("Filtered and Sorted Property Data:", filteredData);

      // Step 4: Set State and Log
      setFilteredPropertyActivityHistory(filteredData);
      console.log("Filtered Property Activity History set in state:", filteredData);

      // Update the properties inspected count
      setPropertiesInspectedCount(filteredData.length);
      console.log("Properties Inspected Count:", filteredData.length);

      // Calculate critical red points and points inspected
      let totalRedPoints = 0;
      let totalUniquePoints = 0;
      let totalPossiblePoints = 0;

      // Define filterVals function (consistent with PropertyCard.js)
      const filterVals = (vals) => {
        return (vals || []).filter(val => {
          const createdAtDate = moment(val?.recorded_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
          return moment(createdAtDate).isBetween(moment(startDate), moment(endDate), undefined, '[]');
        });
      };

      filteredData.forEach(property => {
        const allVals = filterVals(property.vals.edges.map(x => x.node)) || [];
        const allPointInfos = property.pointInfos.edges.map(x => x.node) || [];
        const allMatchedPointInfos = allPointInfos.map(pointInfo => {
          const matchingVal = allVals.find(val => val.point_num === pointInfo.num);
          if (matchingVal) {
            return {
              ...pointInfo,
              matchedValUuid: matchingVal.uuid,
            };
          }
          return null;
        }).filter(info => info && info.matchedValUuid);

        const redCount = allMatchedPointInfos.filter(pi => pi.evalNum > 20).length;
        totalRedPoints += redCount;

        const uniquePointNumCount = new Set(property.vals.edges.map(edge => edge.node.point_num)).size;
        const totalCount = property.pointInfos.totalCount || 0;

        totalUniquePoints += uniquePointNumCount;
        totalPossiblePoints += totalCount;
      });
      console.log("Total Red Points:", totalRedPoints);
      setCriticalRedPoints(totalRedPoints);

      const percentageInspected = totalPossiblePoints > 0 ? Math.floor((totalUniquePoints / totalPossiblePoints) * 100) : 0;
      setPointsInspected(`${totalUniquePoints}/${totalPossiblePoints} (${percentageInspected}%)`);
      console.log("Points Inspected:", `${totalUniquePoints}/${totalPossiblePoints} (${percentageInspected}%)`);


    } catch (error) {
      console.error("Error fetching property activity history:", error);
    }
  };

  const calculatePerpetualScore = (points) => {
    const multipliers = { E1: 4, E2: 3, E3: 2, E4: 1, E5: 0 };
    let numerator = 0;
    let denominator = points.length * 4;
    points.forEach(point => {
      if (point.evalNum !== null) {
        let category;
        if (point.evalNum >= 1 && point.evalNum <= 5) category = 'E1';
        else if (point.evalNum >= 6 && point.evalNum <= 10) category = 'E2';
        else if (point.evalNum >= 11 && point.evalNum <= 15) category = 'E3';
        else if (point.evalNum >= 16 && point.evalNum <= 20) category = 'E4';
        else if (point.evalNum >= 21 && point.evalNum <= 25) category = 'E5';
        if (category) {
          numerator += multipliers[category];
        }
      }
    });
    return Math.round((numerator / denominator) * 100);
  };

  const togglePropertiesDetails = async () => {
    if (!isPropertiesExpanded) {
      setIsPropertiesLoading(true);
      try {
        // Prepare data for DataTable
        const tableData = filteredPropertyActivityHistory.map(property => {
          // Filter and match points
          const allVals = (property.vals.edges || []).map(edge => edge.node);
          const allPointInfos = (property.pointInfos.edges || []).map(edge => edge.node);

          const allMatchedPointInfos = allPointInfos.map(pointInfo => {
            const matchingVal = allVals.find(val => val.point_num === pointInfo.num);
            if (matchingVal) {
              return {
                ...pointInfo,
                matchedValUuid: matchingVal.uuid,
                evalNum: pointInfo.evalNum // Correctly use evalNum from pointInfo
              };
            }
            return null;
          }).filter(info => info && info.matchedValUuid);

          const uniquePointNumCount = new Set(allVals.map(val => val.point_num)).size;
          const totalCount = property.pointInfos.totalCount || 0;
          const percentageInspected = totalCount > 0 ? Math.floor((uniquePointNumCount / totalCount) * 100) : 0;
          const healthScore = calculatePerpetualScore(allMatchedPointInfos);

          // Extract last uploader and last inspection date
          const lastUploader = property.lastValUploaded?.wav?.user?.full_name || 'N/A';

          // Determine the most recent inspection date
          const recordedAt = property.vals?.edges[0]?.node?.recorded_at;
          const createdAt = property.checks?.edges[0]?.node?.created_at;
          const datesWithValues = [
            { date: recordedAt },
            { date: createdAt }
          ].filter(d => d.date);

          const lastInspection = datesWithValues.length
            ? moment(datesWithValues.reduce((a, b) => moment(a.date).isAfter(moment(b.date)) ? a : b).date).local().format("M-D-YY")
            : 'N/A';

          return {
            propertyName: property.name,
            criticalPoint: allMatchedPointInfos.filter(pi => pi.evalNum > 20).length, // Calculate red count
            inspected: uniquePointNumCount,
            totalPoints: totalCount,
            percentInspected: percentageInspected,
            healthScore: healthScore,
            lastUploader: lastUploader, // New column
            lastInspection: lastInspection // Corrected logic
          };
        });

        setInspectedPropertiesDetails(tableData);
      } catch (error) {
        console.error("Error preparing property details for DataTable:", error);
      } finally {
        setIsPropertiesLoading(false);
      }
    }
    setIsPropertiesExpanded(!isPropertiesExpanded);
  };

  const toggleUsersDetails = () => {
    setIsUsersExpanded(!isUsersExpanded);
  };

  const toggleMsiDetails = () => {
    setIsMsiExpanded(!isMsiExpanded);
  };

  const toggleReportsDetails = () => {
    setIsReportsExpanded(!isReportsExpanded);
  };

  const downloadCsv = () => {
    // Define the headers for the CSV
    const headers = ['Metric', 'Value'];

    // Define the data rows for the table
    const rows = [
      ['# of Active Users', activeUsersCount],
      ['# of Msi Used', uniqueMsiCount],
      ['# of Properties Inspected', propertiesInspectedCount],
      ['# of Critical Red Points', criticalRedPoints],
      ['# of Points Inspected', pointsInspected],
      ['# of Reports', reportCount]
    ];

    // Combine the headers and rows, then join them with new lines to form the CSV content
    const csvContent = [
      `Company: ${user.company.title}`,
      `Report: Company Activity Report`,
      `Date Range: ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`,
      '', // Blank line to separate headers from table
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    // Create a blob with the CSV content and trigger a download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const fileName = `company_activity_report_${user.company.title.replace(/\s+/g, '_')}_${moment(startDate).format('YYYYMMDD')}_to_${moment(endDate).format('YYYYMMDD')}.csv`;
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadDataTableCsv = () => {
    if (!inspectedPropertiesDetails.length) {
      console.log("No data available to download.");
      return;
    }

    // Define the headers for the CSV, including the new columns
    const headers = [
      'Property', 
      'Critical Point', 
      '# of Inspected', 
      '# of Total Points', 
      '% Inspected', 
      'Health Score', 
      'Last Uploader',  // New column
      'Last Inspection' // New column
    ];

    // Map the data to CSV format, including the new details
    const rows = inspectedPropertiesDetails.map(detail => [
      detail.propertyName,
      detail.criticalPoint,
      detail.inspected,
      detail.totalPoints,
      detail.percentInspected,
      detail.healthScore,
      detail.lastUploader,   // New detail
      detail.lastInspection  // New detail
    ]);

    // Combine the headers and rows, then join them with new lines to form the CSV content
    const csvContent = [
      `Company: ${user.company.title}`,
      `Report: Inspected Properties Details`,
      `Date Range: ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`,
      '', // Blank line to separate headers from table
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    // Create a blob with the CSV content and trigger a download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const fileName = `inspected_properties_details_${user.company.title.replace(/\s+/g, '_')}_${moment().format('YYYYMMDD_HHmmss')}.csv`;
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchData = async () => {
    console.log("fetchData: Starting data fetch...");
    setIsLoading(true);
    try {
      console.log("fetchData: Initiating parallel data fetch operations...");
      await Promise.all([
        fetchFilteredPropertyActivityHistory(),
        fetchActiveUsers(),
        fetchUniqueMsiCount(),
        fetchReportCount()
      ]);
      console.log("fetchData: Data fetch operations completed successfully.");
    } catch (error) {
      console.error("fetchData: Error fetching data:", error);
    } finally {
      setIsLoading(false);
      console.log("fetchData: Data fetch process ended. Loading state set to false.");
    }
  };

  const applyDateRange = () => {
    console.log("applyDateRange: Applying date range with start date:", tempStartDate, "and end date:", tempEndDate);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setShouldFetch(true); // Trigger fetching
    console.log("applyDateRange: Date range applied and fetch triggered.");
  };

  useEffect(() => {
    console.log("useEffect: Checking if data fetch should be triggered...");
    if (shouldFetch) {
      console.log("useEffect: Fetching data due to date range change or initial load.");
      fetchData();
      setShouldFetch(false); // Reset the flag
      console.log("useEffect: Data fetch completed and fetch flag reset.");
    }
  }, [startDate, endDate, user.company_id, shouldFetch]);
  

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This is for DST Mobile Login Activity
    </Tooltip>
  );

  return (
    <div className="d-flex flex-row justify-content-start align-items-start" style={{ width: '100%', height: '100vh' }}>
      <NavSidebar />
      <Container fluid style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'auto', marginLeft: '20px', marginRight: '20px' }}>
        <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>Company Activity Dashboard</h2>
        
        {/* Date Range Picker */}
        <div className="d-flex flex-row justify-content-center align-items-center mb-4">
          <span className="font-weight-bold align-self-center">Date Range:</span>
          <DatePicker
            onChange={setTempStartDate}
            value={tempStartDate}
            className="ml-2 mr-2"
            calendarIcon={null}
            clearIcon={null}
          />
          <span className="align-self-center">&nbsp;to&nbsp;&nbsp;</span>
          <DatePicker
            onChange={setTempEndDate}
            value={tempEndDate}
            className="ml-2 mr-2"
            calendarIcon={null}
            clearIcon={null}
          />
          <button
            onClick={applyDateRange}
            style={{
              marginLeft: '10px',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '8px 16px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.3s',
              backgroundColor: '#007bff',
              color: '#ffffff',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            Apply
          </button>
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row>
            <Col md={6} lg={4}>
              <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '12px', borderColor: '#e0e0e0', transition: 'transform 0.3s', background: '#ffffff' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: '18px', fontWeight: 'bold', position: 'relative' }}>
                    # of Active Users
                    <span
                      onClick={toggleUsersDetails}
                      style={{ cursor: 'pointer', marginLeft: '10px', color: '#007bff' }}
                    >
                      {isUsersExpanded ? 'Hide Details' : 'Show Details'}
                    </span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <span style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', color: '#007bff', fontSize: '20px' }}>
                        ⓘ
                      </span>
                    </OverlayTrigger>
                  </Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>{activeUsersCount}</Card.Text>
                  {isUsersExpanded && (
                    <ul>
                      {activeUsersDetails.map((name, index) => (
                        <li key={index}>{name}</li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '12px', borderColor: '#e0e0e0', transition: 'transform 0.3s', background: '#f7f7f7' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    # of Msi Used
                    <span
                      onClick={toggleMsiDetails}
                      style={{ cursor: 'pointer', marginLeft: '10px', color: '#007bff' }}
                    >
                      {isMsiExpanded ? 'Hide Details' : 'Show Details'}
                    </span>
                  </Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>{uniqueMsiCount}</Card.Text>
                  {isMsiExpanded && (
                    <ul>
                      {msiDetails.map(([serial, userName], index) => (
                        <li key={index}>
                          {serial} - {userName}
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={isPropertiesExpanded ? 12 : 6} lg={isPropertiesExpanded ? 12 : 4}>
              <Card
                className="mb-3"
                style={{
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  borderRadius: '12px',
                  borderColor: '#e0e0e0',
                  transition: 'transform 0.3s',
                  background: '#ffffff',
                  width: '100%',
                  overflowX: 'auto'
                }}
              >
                <Card.Body>
                  <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    # of Properties Inspected with MSi
                    <span
                      onClick={togglePropertiesDetails}
                      style={{ cursor: 'pointer', marginLeft: '10px', color: '#007bff' }}
                    >
                      {isPropertiesExpanded ? 'Hide Details' : 'Show Details'}
                    </span>
                  </Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>
                    {propertiesInspectedCount}
                  </Card.Text>
                  {isPropertiesLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '50px' }}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    isPropertiesExpanded && (
                      <div style={{ marginTop: '10px' }}>
                        <DataTable
                          title="Property Details"
                          columns={[
                            { name: 'Property', selector: row => row.propertyName, sortable: true, width: '250px' },
                            { name: 'Critical Point', selector: row => row.criticalPoint, sortable: true, width: '150px' },
                            { name: '# of Inspected', selector: row => row.inspected, sortable: true, width: '150px' },
                            { name: '# of Total Points', selector: row => row.totalPoints, sortable: true, width: '150px' },
                            { name: '% Inspected', selector: row => row.percentInspected, sortable: true, width: '150px' },
                            { name: 'Health Score', selector: row => row.healthScore, sortable: true, width: '150px' },
                            { name: 'Last Uploader', selector: row => row.lastUploader, sortable: true, width: '150px' },
                            { name: 'Last Inspection', selector: row => row.lastInspection, sortable: true, width: '150px' }
                          ]}
                          data={inspectedPropertiesDetails}
                          pagination
                          style={{ width: '100%' }}
                        />
                        <button
                          onClick={downloadDataTableCsv}
                          style={{
                            marginTop: '10px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'transform 0.3s',
                            backgroundColor: '#28a745',
                            color: '#ffffff',
                            border: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          Download Table Data (CSV)
                        </button>
                      </div>
                    )
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '12px', borderColor: '#e0e0e0', transition: 'transform 0.3s', background: '#ffffff' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: '22px', fontWeight: 'bold' }}># of Critical Red Points For Inspections</Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>{criticalRedPoints}</Card.Text>
                  {/* <Card.Text style={{ fontSize: '20px' }}>N/A</Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '12px', borderColor: '#e0e0e0', transition: 'transform 0.3s', background: '#ffffff' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: '22px', fontWeight: 'bold' }}># of Points Inspected</Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>{pointsInspected}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '12px', borderColor: '#e0e0e0', transition: 'transform 0.3s', background: '#ffffff' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    # of Reports
                    <span
                      onClick={toggleReportsDetails}
                      style={{ cursor: 'pointer', marginLeft: '10px', color: '#007bff' }}
                    >
                      {isReportsExpanded ? 'Hide Details' : 'Show Details'}
                    </span>
                  </Card.Title>
                  <Card.Text style={{ fontSize: '20px' }}>{reportCount}</Card.Text>
                  {isReportsExpanded && (
                    <ul>
                      {reportDetails.map((detail, index) => (
                        <li key={index}>
                          <a href={detail.link} target="_blank" rel="noopener noreferrer">{detail.link}</a>
                          <div>
                            <strong>Report Type:</strong> {detail.reportType}<br />
                            <strong>Action Log Created At:</strong> {moment(detail.createdAt).format('MMMM Do YYYY, h:mm A')}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* Conditionally render the Download CSV Button */}
        {!isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <button
              onClick={downloadCsv}
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '10px 20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s',
                backgroundColor: '#007bff',
                color: '#ffffff',
                border: 'none',
                cursor: 'pointer'
              }}
            >
              Download Summary Company Activity Data (CSV)
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default CompanyActivityReport;