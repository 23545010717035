import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import ActionLog from '../../models/ActionLog';
import Wav from '../../models/Wav';
import { ActionLogType } from '../../models/ActionLogType';
import moment from 'moment';
import Property from '../../models/Property';
import { ImSpinner3 } from 'react-icons/im';
import Company from '../../models/Company';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import { FcInspection, FcTimeline, FcLibrary, FcBusinesswoman, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcDataSheet, FcCheckmark, FcPackage, FcCableRelease, FcSettings } from 'react-icons/fc';

import { FaChevronLeft, FaMapPin, FaMapMarkerAlt, FaExclamationTriangle } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useAuth } from '../../context/AuthContext';
import { GOOGLEMAP_GEOCODING_API_KEY, GOOGLEMAP_API_KEY } from '../../constants/Constants';
import _ from 'lodash';
import { OverlayTrigger, Overlay, Tooltip, Dropdown } from 'react-bootstrap';
import { EvaluationMark } from './EvaluationMark';
import Utils from '../../common/Utils';
import { IoMdPin } from 'react-icons/io';
import GoogleMapReact from 'google-map-react';
import { ProvideFilterContext, useFilterContext } from "../../context/SharedFilterContext";
import PropertyCard from './PropertyCard';
import ViewportPropertyCard from './PropertyCardViewport';
import UserPanel from './UserPanel';
import MetabasePanel from './MetabasePanel';
import QueryPanel from './QueryPanel';
import ViewServiceTechAtCompanyPanel from './ViewServiceTechAtCompanyPanel';
import ViewMSIUsersAggregatePanel from './ViewMSIUsersAggregatePanel';
import StatusBar from './StatusBar';
import Check from '../../models/Check';

import AnalyticsCountPanel from './AnalyticsCountPanel';
import SoundDatabasePanel from './SoundDatabasePanel';

import Geocode from "react-geocode";
import DatePicker from 'react-date-picker';


Geocode.setApiKey(GOOGLEMAP_GEOCODING_API_KEY);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");


const PropertyActivityGrid = (props) => {
  const {
    hideDateSelector,
    hideStartDate,
    hideEndDate,
    hideAnalysisColors,
    noResultsMessage,
    title,
    submitPath,
    hideMap,
    isInspectionPage,
    isAnalyticsPage,
    hideStructureInfo,
    hideInspectionInfo,
    hideMapLocation,
    hideReportInfo,
    sortByReports,
    filterByReports,
    additionalButtons,
    showPanels,
    showMetabase,
    showAnalyticsCount,
    showSoundDatabase
  } = props;

  const history = useHistory();

  const {
    startDate: sharedStartDate,
    endDate: sharedEndDate,
    setStartDate: setSharedStartDate,
    setEndDate: setSharedEndDate,
    defaultStartDate,
    defaultEndDate,
  } = useFilterContext();

  const {
    user,
    isSuperAdmin,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher,
    isSubdomainAdminOrHigher,
  } = useAuth();

  const [loading, setLoading] = useState(props.loading);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingMsi, setLoadingMsi] = useState(false);


  const [propertyNameFilter, setPropertyNameFilter] = useState("");

  const [propertyActivityHistory, setPropertyList] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);

  const [loadMore, setLoadMore] = useState(() => () => { });

  const [loadedItemsCount, setLoadedItemsCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);

const loadMoreRef = useRef(null);  // Ref for the 'Load More' button

const [totalMsiInspectionsState, setTotalMsiInspections] = useState(0);
const [totalChecks, setTotalChecks] = useState(0);
const [totalMsiDevices, setTotalMsiDevices] = useState(0);
const [totalUniqueUsers, setTotalUniqueUsers] = useState(0);

const [showMap, setShowMap] = useState(false); // New state for toggling map visibility



  const [mapLat, setMapLat] = useState(35.73083320925414); //default to mid TN/NC border
  const [mapLng, setMapLng] = useState(-83.72437148505048);
  const [mapZoom, setMapZoom] = useState(7);
  const defaultLat = 35.73083320925414; 
  const defaultLng = -83.72437148505048;
  const defaultZoom = 6; 
  const initialZoomLevel = 6; 


  const [startDate, setStartDateRaw] = useState(sharedStartDate);
  const [endDate, setEndDateRaw] = useState(sharedEndDate);

  const debouncedPropertyNameFilter = Utils.useDebounce(propertyNameFilter, 2500);
  const debouncedStartDate = Utils.useDebounce(startDate, 1000);
  const debouncedEndDate = Utils.useDebounce(endDate, 1000);
  const [hasExternalUuid, setHasExternalUuid] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(sharedStartDate);
  const [tempEndDate, setTempEndDate] = useState(sharedEndDate);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  // const setStartDate = (date) => {
  //   setStartDateRaw(date);
  //   setSharedStartDate(date);
  // }

  // const setEndDate = (date) => {
  //   setEndDateRaw(date);
  //   setSharedEndDate(date);
  // }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      applyDateRange();
    }
  };

  const setStartDate = (date) => {
    setTempStartDate(date);
  };
  
  const setEndDate = (date) => {
    setTempEndDate(date);
  };
  
  const applyDateRange = () => {
    setStartDateRaw(tempStartDate);
    setSharedStartDate(tempStartDate);
    setEndDateRaw(tempEndDate);
    setSharedEndDate(tempEndDate);
    search();
  };
  const setSelectedPropertyResult = async (property) => {
    if (property) {
      console.log({ submitPath })
      history.push(`${submitPath}/${property.uuid}`)
    }
  }

  const sortProperties = (properties, startDate, endDate) => {
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment(endDate).endOf('day');

    let sortedProperties = properties
      .filter(property => {
        const recordedAtDate = property?.vals?.edges[0]?.node?.recorded_at || '';
        const createdAtDate = property?.checks?.edges[0]?.node?.created_at || '';
        const lastEvaluationAtDate = property?.last_evaluation_at || '';

        const recordedAtMoment = moment(recordedAtDate).startOf('day');
        const createdAtMoment = moment(createdAtDate).startOf('day');
        const lastEvaluationAtMoment = moment(lastEvaluationAtDate).startOf('day');
        const momentsArray = [recordedAtMoment, createdAtMoment, lastEvaluationAtMoment].filter(m => m.isValid());

        if (momentsArray.length === 0) return false;

        const mostRecentMoment = momentsArray.length > 0 ? moment.max(...momentsArray) : null;

        return mostRecentMoment.isBetween(startMoment, endMoment, null, '[]');
      })
      .sort((a, b) => {
        const momentsA = [
          moment(a?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(a?.checks?.edges[0]?.node?.created_at || '').startOf('day'),
          moment(a?.last_evaluation_at || '').startOf('day')
        ].filter(m => m.isValid());

        const momentsB = [
          moment(b?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(b?.checks?.edges[0]?.node?.created_at || '').startOf('day'),
          moment(b?.last_evaluation_at || '').startOf('day')
        ].filter(m => m.isValid());

        const mostRecentDateA = momentsA.length > 0 ? moment.max(...momentsA) : null;
        const mostRecentDateB = momentsB.length > 0 ? moment.max(...momentsB) : null;

        return mostRecentDateA.isAfter(mostRecentDateB) ? -1 : 1;
      });

    return sortedProperties;
  };

  const queryPropertiesGeneric = async () => {
    const propertiesInCompany = await Company.filteredProperty(
      user && user.id,
      user && user.company && user.company.uuid,
      null,
      propertyNameFilter,
      null
    );

    console.log({ propertiesInCompany })
    const resultProperties = propertiesInCompany.data.map(company => {
      let propertyList = company.propertiesFilter;
      if (!sortByReports) {
        propertyList = _.sortBy(_.sortBy(company.propertiesFilter, o => !isNaN(parseInt(o.name)), 'name'))
      }
      if (filterByReports) {
        propertyList = propertyList.filter(property => property.lastReportRun && moment(property.lastReportRun.created_at).isAfter(startDate))
      }
      return _.orderBy(propertyList, [
        property => sortByReports
          ? (property.lastReportRun ? moment(property.lastReportRun.created_at).unix() : -1)
          : moment(property.updated_at).unix(),
      ],
        ['desc', 'desc']);
    });



    let propertyList = resultProperties.length === 1
      ? resultProperties[0] 
      : resultProperties;

    return propertyList;
  }

 
  const queryProperties = async (userId, propertyNameFilter, propertyFilterFunction, pageSize = 20) => {
   
    const propertiesUuidInCompany = await Company.detailedFilteredPropertyUuidActivity(
      userId,
      user?.company?.uuid,
      null,
      propertyNameFilter,
      startDate,
      endDate
    );
  //const propertyUUIDs = propertiesUuidInCompany?.data?.flatMap(company => company?.propertiesFilter)?.map(property => property?.uuid);
  const sortedProperties = propertiesUuidInCompany?.data?.flatMap(company => company?.propertiesFilter)
  .sort((a, b) => {
    const lastUploadA = a.last_upload_at ? moment(a.last_upload_at) : moment(0);
    const lastUploadB = b.last_upload_at ? moment(b.last_upload_at) : moment(0);

    const firstCheckCreatedA = a.checks?.edges[0]?.node?.created_at ? moment(a.checks.edges[0].node.created_at) : moment(0);
    const firstCheckCreatedB = b.checks?.edges[0]?.node?.created_at ? moment(b.checks.edges[0].node.created_at) : moment(0);

    const mostRecentA = moment.max(lastUploadA, firstCheckCreatedA);
    const mostRecentB = moment.max(lastUploadB, firstCheckCreatedB);

    return mostRecentB.diff(mostRecentA);
  });
  const propertyUUIDs = sortedProperties?.map(property => property?.uuid);

  console.log("This is the propertiesUuidInCompany (sorted), its the first things we query for in the whole process", sortedProperties);
  let nextIndex = 0;
    
    const loadAdditionalProperties = async () => {
      setIsLoadingMore(true);
      if (nextIndex === 0) {
          setPropertyList([]);
      }
      const currentBatchUUIDs = propertyUUIDs.slice(nextIndex, nextIndex + pageSize);
      nextIndex += pageSize;
  
      // Fetch data for the batch of UUIDs
      const morePropertiesBatch = await Property?.getCardData(currentBatchUUIDs, userId, startDate, endDate);
      console.log("This is the properties card data in batch", morePropertiesBatch);
      const moreProperties = morePropertiesBatch.filter(property => property !== null);
  
      setPropertyList(prevList => {
          // Create a unique list of properties by UUID and then map to the full property objects
          const uniqueUuids = [...new Set([...prevList, ...moreProperties].map(item => item.uuid))];
          return uniqueUuids.map(uuid => prevList.find(item => item.uuid === uuid) || moreProperties.find(item => item.uuid === uuid));
      });
  
      if (nextIndex >= propertyUUIDs.length) {
          setHasMoreItems(false);
      }
      setIsLoadingMore(false);
  };
  



    return loadAdditionalProperties; // return the function to load more properties
  };




  const propertyToAddress = (property) => {
    return `${property.street1 || ''} ${property.street2 || ''} ${property.city || ''} ${property.state || ''} ${property.zip || ''}`;
  }

  const geocodePropertyList = async (propertyList) => {
    const geolocatedPropertyListPromises = propertyList.map(property => {
      if (!property.lat || !property.lng) {
        Geocode.fromAddress(propertyToAddress(property)).then(response => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(propertyToAddress(property), property.lat, lat, property.lng, lng)
          property.lat = lat;
          property.lng = lng;
          return Promise.resolve(property)
        })
      }
      return Promise.resolve(property);
    })
    const allProperties = await Promise.allSettled(geolocatedPropertyListPromises)
    return allProperties.map(p => p.value);
  }

  const calculateWeightedCenter = (markers) => {
    let latSum = 0, lngSum = 0, totalWeight = 0;
  
    markers.forEach(marker => {
      const weight = 1;
      latSum += marker.lat * weight;
      lngSum += marker.lng * weight;
      totalWeight += weight;
    });
  
    if (totalWeight === 0) {
      return null; 
    }
  
    return {
      lat: latSum / totalWeight,
      lng: lngSum / totalWeight
    };
  };
  

  const updateMapCenterAndZoom = (propertyList) => {
    propertyList = propertyList.filter(p => p.lat !== null && p.lng !== null);
    const center = calculateWeightedCenter(propertyList);
    if (center) {
      setMapLat(center.lat);
      setMapLng(center.lng);
      setMapZoom(initialZoomLevel);

    } else {
      setMapLat(defaultLat);
      setMapLng(defaultLng);
      setMapZoom(defaultZoom); 

    }
  
  }
  

  const search = async () => {
    setIsSearchLoading(true); // Set search loading state to true

    if (!user.selectedProperty) {
      setLoading(true);

      let loadMoreProperties;

      if (isInspectionPage) {

        loadMoreProperties = await queryProperties(
          user.id,
          propertyNameFilter,
          property => {
            const lastUpload = property.last_upload_at !== null ? moment(property.last_upload_at).unix() : 0;
            const firstCheck = property.checks && property.checks.edges.length > 0
              ? moment(property.checks.edges[0].node.created_at).unix()
              : 0;
            return Math.max(lastUpload, firstCheck);
          }
        );

      
      } else if (isAnalyticsPage) {
        
        loadMoreProperties = await queryProperties(
          user.id,
          propertyNameFilter,
          property => property.last_upload_at !== null ? moment(property.last_upload_at).unix() : -1
        );

      } else {
        let propertyList = await queryPropertiesGeneric();
        setPropertyList(propertyList);
        setLoading(false);
        return;

      }

      // Load initial batch of properties
      await loadMoreProperties();

      // Store the function for loading more properties
      setLoadMore(() => loadMoreProperties);

      setLoading(false);
    }
    setIsSearchLoading(false); // Set search loading state to true

  };
  

  useEffect(
    search,
    [user && user.company && user.company.id, debouncedStartDate, debouncedEndDate]
  )

  const startMoment = moment(startDate).startOf('day');
  const endMoment = moment(endDate).endOf('day');

  useEffect(() => {
    setLoadingMsi(true);
    let filteredPropertyActivityHistory = propertyActivityHistory
      .filter(row => {
        // Retrieve the two dates 
        const recordedAtDate = row?.vals?.edges[0]?.node?.recorded_at || '';
        const createdAtDate = row?.checks?.edges[0]?.node?.created_at || '';

        const recordedAtMoment = moment(recordedAtDate).startOf('day');
        const createdAtMoment = moment(createdAtDate).startOf('day');
        const momentsArray = [recordedAtMoment, createdAtMoment].filter(m => m.isValid());
        if (momentsArray.length === 0) return false;

        // Determine the most recent of the two dates
        const mostRecentMoment = momentsArray.length > 0 ? moment.max(...momentsArray) : null;

        // Check if the most recent date is between startMoment and endMoment
        return mostRecentMoment.isBetween(startMoment, endMoment, null, '[]');
      })
      .sort((a, b) => {

        // Extract the moments for 'a'
        const momentsA = [
          moment(a?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(a?.checks?.edges[0]?.node?.created_at || '').startOf('day')
        ].filter(m => m.isValid());

        // Extract the moments for 'b'
        const momentsB = [
          moment(b?.vals?.edges[0]?.node?.recorded_at || '').startOf('day'),
          moment(b?.checks?.edges[0]?.node?.created_at || '').startOf('day')
        ].filter(m => m.isValid());

        // Determine the most recent date among the two for both 'a' and 'b'
        const mostRecentDateA = momentsA.length > 0 ? moment.max(...momentsA) : null;
        const mostRecentDateB = momentsB.length > 0 ? moment.max(...momentsB) : null;

        // If A is before B, return 1 to sort descending
        if (mostRecentDateA.isBefore(mostRecentDateB)) {
          return 1;
        }

        // If A is after B, return -1 to sort descending
        if (mostRecentDateA.isAfter(mostRecentDateB)) {
          return -1;
        }

        // If both are equal
        return 0;
      });


    let dataToRenderVar = (isInspectionPage || isAnalyticsPage) ? filteredPropertyActivityHistory : propertyActivityHistory;
    setDataToRender(dataToRenderVar);
    console.log("This is the dataToRender", dataToRenderVar); 
    console.log("This is the propertyActivityHistory before we filter and sort", propertyActivityHistory);
    if(isInspectionPage || isAnalyticsPage) {
// Initialize a Set outside the reduce method to keep track of all unique unit_dec values
let uniqueDeviceUnitDecs = new Set();
const totalCounts = dataToRenderVar.reduce((acc, curr) => {
  // Count unique inspections based on point_num
  const equipmentCount = curr?.vals
    ? Object.keys(_.groupBy(curr?.vals?.edges, edge => edge?.node && edge?.node?.point_num)).length
    : 0;

  // Add each unit_dec to the Set for unique MSI devices
  curr?.vals?.edges.forEach(edge => {
    if (edge?.node && edge?.node?.wav && edge?.node?.wav?.unit_dec) {
      uniqueDeviceUnitDecs.add(edge?.node?.wav?.unit_dec);
    }
  });

  // Update the accumulator for inspections
  acc.inspections += equipmentCount;

  return acc;
}, { inspections: 0 }); // Initial accumulator now includes counter for inspections only

// Set the total counts using respective state update functions
setTotalMsiInspections(totalCounts.inspections);
// The total unique devices is the size of uniqueDeviceUnitDecs
setTotalMsiDevices(uniqueDeviceUnitDecs.size);

const totalUniqueUsers = dataToRenderVar.reduce((acc, curr) => {
  // Use moment to handle dates, ensuring full-day coverage
  const startMoment = moment(startDate).startOf('day');
  const endMoment = moment(endDate).endOf('day');
  
  // 1st Criteria: Collect unique user IDs from vals.edges.node.user.id
  const userIDsFromVals = curr?.vals?.edges
    .map(edge => edge?.node?.wav?.user?.id.toString())
    .filter(id => id !== undefined);
  // 3rd Criteria: Collect unique user IDs from checks.edges.node.user.id
  const userIDsFromChecks = curr?.checks?.edges
    .map(edge => edge?.node?.user?.id.toString())
    .filter(id => id !== undefined);

  const combinedUserIDs = [...userIDsFromVals, ...userIDsFromChecks];

  // Add last_evaluation_user_id if it exists and is within the date range
  if (curr.last_evaluation_user_id && curr.last_evaluation_at) {
    const evalMoment = moment(curr.last_evaluation_at);
    if (evalMoment.isBetween(startMoment, endMoment, null, '[]')) {
      combinedUserIDs.push(curr.last_evaluation_user_id.toString());
    }
  }

  // Add unique IDs from this iteration to the accumulator Set
  //combinedUserIDs.forEach(id => acc.add(id));
  acc = new Set([...acc, ...combinedUserIDs]);


  return acc;
}, new Set()).size; // Use a Set to automatically handle uniqueness and .size for the count

  
  // Set the total unique user IDs count as totalUniqueUsers
  setTotalUniqueUsers(totalUniqueUsers);
  

  
  setLoadingMsi(false);

}
  

    // Check if any element has an external_uuid and update the state
    const anyExternalUuid = dataToRenderVar.some(row => row.external_uuid !== undefined);
    setHasExternalUuid(anyExternalUuid);


  }, [propertyActivityHistory]);


  useEffect(() => {
    if (propertyActivityHistory && propertyActivityHistory.length > 0) {
      updateMapCenterAndZoom(propertyActivityHistory);
    }
  }, [propertyActivityHistory]);

  // const loadTotalChecks = async () => {
  //   setLoadingTask(true);

  //   let totalCount = 0;
  
  //   // Iterate over each property in dataToRender
  //   for (const property of dataToRender) {
  
  //     // Iterate over each fig in the property's figs array
  //     for (const fig of property.figs) {
  //       // Check if the fig has at least one sheet
  //       if (fig.sheets && fig.sheets.length > 0) {
  //         const firstSheet = fig.sheets[0]; // Use only the first sheet
  //         let sections = firstSheet.sections || [];
  //         let tasks = firstSheet.tasks || [];
  
  //         // Collect task UUIDs from the first sheet's sections and tasks
  //         const taskUUIDs = [
  //           ...sections.flatMap(section => (section.tasks || []).map(task => task.uuid)),
  //           ...tasks.map(task => task.uuid)
  //         ].filter(Boolean);
  
  //         // Fetch checks for these task UUIDs
  //         const figChecks = await Check.filterBy([
  //           { field: 'task_uuid', value: JSON.stringify(taskUUIDs), comparison: "IN" },
  //           { field: "archived", value: 0, comparison: "=" },
  //         ], "created_at", "desc", 250, undefined, undefined, undefined, true);
  
  //         let resultChecks = figChecks.data;
  
  //         // Filter checks by date range if specified
  //         if (startDate && endDate) {
  //           const oneDayAfterEndDate = moment(endDate).add(1, 'day');
  //           resultChecks = resultChecks.filter(check =>
  //             moment.tz(check.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate)
  //           );
  //         }
  
  //         // Further filter checks by the fig UUID
  //         resultChecks = resultChecks.filter(check => check.fig_uuid === fig.uuid);
  //         totalCount += resultChecks.length; // Accumulate the count of relevant checks
  //       }
  //     }
  //   }
  
  //   // Update the total checks count in state
  //   setTotalChecks(totalCount);
  //   setLoadingTask(false);

  // };

  
  const loadTotalChecks = async () => {
    setLoadingTask(true);
  
    // Initialize a Set to keep all unique task UUIDs
    let allTaskUUIDs = new Set();
    // Initialize an object to map fig UUIDs to their corresponding task UUIDs
    let figToTaskUUIDsMap = {};
    // Iterate through each property to collect task UUIDs and map them by fig UUIDs
    dataToRender.forEach(property => {
      property?.figs?.forEach(fig => {
        if (fig?.sheets?.length > 0) {
          const sheet = fig?.sheets[0]; // Assuming checks relate to the first sheet
          const sectionTaskUUIDs = sheet?.sections?.flatMap(section => section?.tasks?.map(task => task?.uuid?.toString())) ?? [];
          const taskUUIDs = sheet?.tasks?.map(task => task?.uuid?.toString()) ?? [];
          const uniqueTaskUUIDs = [...new Set([...sectionTaskUUIDs, ...taskUUIDs])]; // Ensure uniqueness
          figToTaskUUIDsMap[fig?.uuid?.toString()] = uniqueTaskUUIDs; // Map fig UUID to task UUIDs
          uniqueTaskUUIDs.forEach(uuid => allTaskUUIDs.add(uuid)); // Collect all unique task UUIDs
        }
      });
    });
  
    let totalCount = 0;
    let fetchedChecks = [];
    if (allTaskUUIDs.size > 0) {
      // Fetch checks for all unique task UUIDs in one go
      const response = await Check.filterBy([
        { field: 'task_uuid', value: JSON.stringify([...allTaskUUIDs]), comparison: "IN" },
        { field: "archived", value: 0, comparison: "=" }
      ], "created_at", "desc", 1000);
      fetchedChecks = response?.data ?? [];
    }
  
    // Filter the fetched checks based on the fig UUIDs and the date range, then accumulate the count
    Object.entries(figToTaskUUIDsMap).forEach(([figUUID, taskUUIDs]) => {
      let figChecks = fetchedChecks.filter(check => 
        taskUUIDs.includes(check?.task_uuid?.toString()) && check?.fig_uuid?.toString() === figUUID
        && moment.tz(check?.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), moment(endDate).add(1, 'day'))
      );
      totalCount += figChecks.length;
    });
  
    // Update the total checks count in state
    setTotalChecks(totalCount);
    setLoadingTask(false);
  };
  
  
  

  
  // useEffect(() => {
  //  loadTotalChecks();
  // }, [dataToRender]);

  



  const GoogleMap = (props) => {
    const [propertyMarkers, setPropertyMarkers] = useState([]);

    useEffect(() => {

      setPropertyMarkers(props.propertyActivityHistory)
    }, [props.propertyActivityHistory])

    return (
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLEMAP_API_KEY }}
          center={{
            lat: props.mapLat,
            lng: props.mapLng
          }}
          defaultZoom={mapZoom}
          hoverDistance={20}
        >
          {propertyMarkers && propertyMarkers.filter(pa => pa.lat !== null && pa.lng !== null).map(pa => {
            return (
              <abbr
                key={pa.uuid}
                lat={pa.lat}
                lng={pa.lng}
                text={pa.name}
                title={pa.name}
              >
                <FaMapMarkerAlt
                  style={{ fontSize: '20px', position: 'absolute', transform: 'translate(-50%, -50%)', color: pa.lat === props.mapLat && pa.lng === props.mapLng ? 'blue' : null }}
                  onClick={() => {
                    setSelectedPropertyResult(pa)
                  }}
                />
              </abbr>

            )
          })}
        </GoogleMapReact>
      </div>
    )
  }


  return (
    <>
      {user && !user.selectedProperty && <div className="d-flex flex-column" style={{ position: 'sticky', top: '-2.5em', zIndex: 100, backgroundColor: 'white' }}>
        <div className="d-flex flex-row justify-content-between">
          <h3>{title}</h3>
          <div className="d-flex flex-row justify-content-end">
          {(!showMetabase && !showPanels && !showAnalyticsCount && !showSoundDatabase) && !hideMap &&
  <div className="mt-1">
    <button 
      onClick={() => setShowMap(!showMap)} 
      style={{
        padding: '8px 16px',
        backgroundColor: '#0056b3',
        color: '#fff',
        border: '1px solid #0056b3',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '-10px',    
        marginBottom: '10px',
        marginRight: '20px',  
        fontSize: '14px',
        fontWeight: '500',
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        outline: 'none'
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#004494';
        e.target.style.borderColor = '#004494';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '#0056b3';
        e.target.style.borderColor = '#0056b3';
      }}
    >
      {showMap ? 'Hide Map' : 'Show Map'}
    </button>
  </div>
}

            {/* {(!showMetabase && !showPanels && !showAnalyticsCount && !showSoundDatabase) && !hideDateSelector && (!hideStartDate || !hideEndDate) && <div className="">
              <span className="font-weight-bold align-self-center">Date Range:</span>

              {!hideStartDate && <>
                <DatePicker onChange={(value) => {
                  const startDate = moment(value)
                  const newStartDate = startDate.endOf("day").format("YYYY-MM-DD");
                  setStartDate(newStartDate)
                }} value={moment(startDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null} />
              </>}

              {!hideStartDate && !hideEndDate && <>
                <span className="align-self-center">&nbsp;to&nbsp;&nbsp;</span>
              </>}

              {!hideEndDate && <>
                <DatePicker onChange={(value) => {
                  const endDate = moment(value)
                  const newDate = endDate.endOf("day").format("YYYY-MM-DD");
                  setEndDate(newDate)
                }} value={moment(endDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null} />
              </>}
            </div>} */}

            {(!showMetabase && !showPanels && !showAnalyticsCount && !showSoundDatabase) && !hideDateSelector && (!hideStartDate || !hideEndDate) && <div className="">
              <span className="font-weight-bold align-self-center">Date Range:</span>

              {!hideStartDate && <>
                <DatePicker onChange={(value) => {
                  const startDate = moment(value);
                  const newStartDate = startDate.endOf("day").format("YYYY-MM-DD");
                  setStartDate(newStartDate);
                }} value={moment(tempStartDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null} />
              </>}

              {!hideStartDate && !hideEndDate && <>
                <span className="align-self-center">&nbsp;to&nbsp;&nbsp;</span>
              </>}

              {!hideEndDate && <>
                <DatePicker onChange={(value) => {
                  const endDate = moment(value);
                  const newDate = endDate.endOf("day").format("YYYY-MM-DD");
                  setEndDate(newDate);
                }} value={moment(tempEndDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null} />
              </>}

              <button 
              onClick={applyDateRange} 
              style={{
                padding: '4px 10px',
                backgroundColor: '#0056b3',
                color: '#fff',
                border: '1px solid #0056b3',
                borderRadius: '4px',
                cursor: 'pointer',
                marginRight: '10px',  
                fontSize: '12px',
                fontWeight: '500',
                transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                outline: 'none'
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#004494';
                e.target.style.borderColor = '#004494';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '#0056b3';
                e.target.style.borderColor = '#0056b3';
              }}
            >
              Apply
            </button>           
  
           </div>}
            {(!showMetabase && !showPanels && !showAnalyticsCount && !showSoundDatabase) &&
              <div className="">
                <span className="font-weight-bold align-self-center">{!hideStartDate && !hideEndDate && "or "} Search by Name:</span>
                <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="search-tooltip">Press Enter to Search</Tooltip>}
        >
                <input type="text" placeholder="Name"  onKeyPress={handleKeyPress} onChange={(e) => {
                  setStartDate(moment(defaultEndDate).subtract(1, 'year').endOf("day").format("YYYY-MM-DD"))
                  setEndDate(defaultEndDate)
                  setPropertyNameFilter(e.target.value);



                }}></input>
                        </OverlayTrigger>

              </div>
            }
            {additionalButtons && additionalButtons.map(button => {
              return button;
            })}
          </div>
        </div>

        {!showMetabase && !showPanels && !showAnalyticsCount && !showSoundDatabase && user && !user.selectedProperty && !hideMap &&
  <div className="mt-1">
  
    {showMap && <GoogleMap mapLat={mapLat} mapLng={mapLng} propertyActivityHistory={dataToRender} />}
  </div>
}



        {(!showPanels && !showMetabase && !showAnalyticsCount && !showSoundDatabase) ?
          // First section
          <div className="d-flex flex-column align-items-center"> {/* Flex column for overall layout */}
            <div className="d-flex flex-row" style={{ overflowY: "hidden", overflowX: (loading || propertyActivityHistory.length === 0) ? "hidden" : "scroll" }}>
              {user && !user.selectedProperty && (loading
                ? <ImSpinner3 className="icon-spin" style={{ fontSize: 25, marginTop: 10 }} />
                : <div>
                  <div className={`d-flex flex-row flex-wrap justify-content-start`}>
                    {dataToRender.length === 0 && !isSearchLoading &&!loading && <div>{noResultsMessage}</div>}
                    {dataToRender.map((row, index) => (
                      <PropertyCard
                        index={index}
                        key={index}
                        row={row}
                        hideMapLocation={hideMapLocation}
                        inspectionInfo={isInspectionPage}
                        hideReportInfo={hideReportInfo}
                        setSelectedPropertyResult={setSelectedPropertyResult}
                        mapLat={mapLat}
                        mapLng={mapLng}
                        hasExternalUuid={hasExternalUuid}
                        hideStructureInfo={hideStructureInfo}
                        hideInspectionInfo={hideInspectionInfo}
                        hideAnalysisColors={hideAnalysisColors}
                        sortByReports={sortByReports}
                        setMapCoordinates={(lat, lng) => {
                          setMapLat(lat)
                          setMapLng(lng)
                          setMapZoom(12)
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            {(dataToRender.length !== 0) && (isAnalyticsPage || isInspectionPage) && !loading ? (
  hasMoreItems  ? (
    <button ref={loadMoreRef} onClick={loadMore} style={{
        padding: '10px 20px',
        margin: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        {isLoadingMore ? (
            <ImSpinner3 className="icon-spin" style={{ fontSize: 25 }} />
        ) : (
            "Load More"
        )}
    </button>
  ) : (
    <div style={{
        textAlign: 'center',
        margin: '20px',
        color: '#606060'
    }}>
      No More Properties to Load, refresh the page to fetch any newly added properties
    </div>
  )
) : null}


          </div>

          :
          showMetabase ?
            // Second section
            <div className="d-flex flex-row">
              <div className="property-activity-grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <QueryPanel
                  title="Total number of service technicians at company"
                  description="Click to view the chart"
                  ChartComponent={ViewServiceTechAtCompanyPanel}
                  style={{ width: '48%', boxSizing: 'border-box' }}
                />

                <QueryPanel
                  title="Number of unique users who connected with a MSi150 over time"
                  description="Click to view the chart"
                  ChartComponent={ViewMSIUsersAggregatePanel}
                  style={{ width: '48%', boxSizing: 'border-box' }}
                />
              </div>
            </div>


            :
            showAnalyticsCount ?
              // Third section
              <div className="d-flex flex-row">
                <div className={`d-flex flex-row flex-wrap justify-content-start`}>
                  <AnalyticsCountPanel
                    title={"Analytics Count Figure"}
                    description={"Old Platform Count Info Component"}
                  //dest={'/analytics_count_screen'}
                  />
                </div>
              </div>
              :
              showSoundDatabase ?
                // Fourth section
                <div className="d-flex flex-row">
                  <div className={`d-flex flex-row flex-wrap justify-content-start`}>
                    <SoundDatabasePanel
                      title={"Sound Database Files"}
                      description={"Sound Reference Files"}
                    //dest={'/'}
                    />
                  </div>
                </div>
                :
                // Fifth section
                <div className="d-flex flex-row">

                  <div>
                    <div className={`d-flex flex-row flex-wrap justify-content-start`}>
                      
                    {(isAdminOrHigher() || isSubdomainAdminOrHigher()) &&
                  <UserPanel
                  title={"Company Activity Dashboard"}
                  description={"Activity"}
                  dest={`/company_activity_report`}
                />                      }
                      
                      {
                        // (isSubdomainAdminOrHigher() || (isCustomerTechOrHigher() && isLimitedAdminOrHigher())) &&
                        ((isCustomerTechOrHigher())) &&

                        <UserPanel
                          title={"Administrator"}
                          description={isAdminOrHigher() ? "User Profiles" : "My Profile"}
                          dest={`/users`}
                        />
                      }

              
             
                      {(isAdminOrHigher() || isSubdomainAdminOrHigher()) &&
                        <UserPanel
                          title={"Property Importer"}
                          description={"Importer"}
                          dest={`/import`}
                          disabled={!(isSuperAdmin() || isSubdomainAdminOrHigher())}
                        />

                      }
                         {(isAdminOrHigher() || isSubdomainAdminOrHigher()) &&
                        <UserPanel
                          title={"Metrics"}
                          description={"Admin Metrics"}
                          dest={`/metrics_screen`}
                          //disabled={!(isSuperAdmin() || isSubdomainAdminOrHigher())}
                        />

                      }


                

                    </div>
                  </div>


                </div>


        }


      </div>
      }
    </>

  )
}


export default PropertyActivityGrid;
