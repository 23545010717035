import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import {EvaluationMark} from './EvaluationMark';
import { FaExclamationTriangle, FaMapMarkerAlt, FaEdit } from 'react-icons/fa';
import { FcInspection, FcTimeline, FcLibrary, FcBusinesswoman, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcDataSheet, FcCheckmark, FcPackage, FcCableRelease, FcSettings } from 'react-icons/fc';
import {GrGroup, GrUser, GrBarChart} from 'react-icons/gr';
import {HiOutlineOfficeBuilding, HiDownload, HiExclamation} from 'react-icons/hi';

import moment from 'moment';
import {useFilterContext} from '../../context/SharedFilterContext';
import { GOOGLEMAP_API_KEY } from '../../constants/Constants';
import {processContext} from './ReportTable';
import ImageUploading from 'react-images-uploading';
import Property from '../../models/Property';
import { ImSpinner3 } from 'react-icons/im';
import '../../styles/Panel.css';


const UserPanel = (props) => {
  const { 
    title, 
    description,
    dest,
    disabled,
    iconSize = '75px', // Default icon size
  } = props;
  
  const isClickable = !disabled;


  // Function to determine which icon to display
  const renderIcon = () => {
    switch(dest) {
      case '/users':
        return <GrGroup style={{ fontSize: iconSize }} />;
      case '/sound_database_screen':
        return <FcElectricalSensor style={{ fontSize: iconSize, filter: 'grayscale(100%)' }} />;
      case '/metrics_screen':
        return <GrBarChart style={{ fontSize: iconSize, filter: 'grayscale(100%)' }} />;
      case '/import':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HiOutlineOfficeBuilding style={{ fontSize: iconSize }} />
            <div style={{ width: '2px', height: iconSize, backgroundColor: 'lightgrey', margin: '0 8px' }}></div>
            <HiDownload style={{ fontSize: iconSize }} />
          </div>
        );
      case '/company_activity_report':
        return <FcDocument style={{ fontSize: iconSize, filter: 'grayscale(100%)' }} />;
      default:
        return <HiExclamation style={{ fontSize: iconSize }} />;
    }
  };

  return (
    <Card
      className="user-panel-card"
      style={!isClickable ? { opacity: 0.5 } : {}}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100px' 
      }}>
        {renderIcon()}
      </div>
      <Card.Body>
        {isClickable ? (
          <Link className="user-panel-link" to={dest}>
            <Card.Title className="user-panel-title">{title}</Card.Title>
          </Link>
        ) : (
          <Card.Title className="user-panel-title">{title}</Card.Title>
        )}
        <Card.Text className="user-panel-description">
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default UserPanel;