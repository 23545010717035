// platform/src/models/WorkOrders.js
import Model from './Model';

class WorkOrders extends Model{

    static getCorrigoWorkOrdersByProperty = async (property_external_uuid) => {
        console.log(`getCorrigoWorkOrdersByProperty: Fetching work orders for property: ${property_external_uuid}`);
        
        const res = await this.runQuery(GetCorrigoWorkOrdersByProperty, { property_external_uuid });

        console.log("getCorrigoWorkOrdersByProperty: Query result:", res);
        return res.data.getCorrigoWorkOrdersByProperty;
    };


    static getAllCorrigoWorkOrders = async () => {
        console.log("getAllCorrigoWorkOrders: Starting query...");
        const res = await this.runQuery(GetAllCorrigoWorkOrders);
        console.log("getAllCorrigoWorkOrders: Query result:", res);
        return res.data.getAllCorrigoWorkOrders;
    }

    static getAllCorrigoDSTWorkOrders = async () => {
        console.log("getAllCorrigoDSTWorkOrders: Starting query...");
        const res = await this.runQuery(GetAllCorrigoDSTWorkOrders);
        console.log("getAllCorrigoDSTWorkOrders: Query result:", res);
        return res.data.getAllCorrigoDSTWorkOrders;
    }

}

const GetAllCorrigoWorkOrders = `
query GetAllCorrigoWorkOrders {
    getAllCorrigoWorkOrders {
        id
        workOrderId
        uuid
        number
        typeCategory
        statusId
        priority
        createdAt
        updatedAt
        dtCreated
        dtDue
        customerId
        isWarranty
        totalSpotCost
    }
}`;

const GetAllCorrigoDSTWorkOrders = `
query GetAllCorrigoDSTWorkOrders {
    getAllCorrigoDSTWorkOrders {
        id
        uuid
        number
        typeCategory
        statusId
        createdAt
        updatedAt
        customerId
        isWarranty
        totalSpotCost
    }
}`;


const GetCorrigoWorkOrdersByProperty = `
query GetCorrigoWorkOrdersByProperty($property_external_uuid: String!) {
    getCorrigoWorkOrdersByProperty(property_external_uuid: $property_external_uuid) {
        id
        work_order_id
        uuid
        number
        x_number
        type_category
        work_order_cost_id
        priority_id
        priority
        status_id
        requestor_contact_id
        access
        main_asset_id
        short_location
        task_refinement
        work_zone_id
        employee_id
        duration
        address_id
        bill_to_type
        bill_to_option_id
        vendor_nte_currency
        vendor_nte_value
        specialty_id
        po_number
        sub_type_id
        repair_code_id
        customer_id
        customer_nte_currency
        customer_nte_value
        billing_rule
        costs_total_value
        costs_total_currency
        vendor_invoice_total_value
        vendor_invoice_total_currency
        expenses_total_value
        expenses_total_currency
        service_fees_total_value
        service_fees_total_currency
        contact_name
        contact_address_id
        won_id
        dt_scheduled_start
        scheduled_start_utc
        is_warranty
        flag_id
        time_zone
        currency_type_id
        bill_status
        ap_state_id
        ap_status_id
        is_pre_billed
        cost_state
        cost_status_id
        job_code
        authorization_code
        check_number
        payment_amount_value
        payment_amount_currency
        payment_notes
        check_cut_date
        check_clear_date
        vendor_invoice_date
        vendor_invoice_received_date
        wo_items_sort_order_idx
        wo_items_asset_location
        wo_items_asset_id
        wo_items_task_id
        wo_items_comment
        equipment_asset_id
        equipment_comment
        equipment_refrigerant_used
        equipment_type
        procedure_template_id
        procedure_asset_id
        procedure_type
        procedure_allow_mark_all
        procedure_allow_not_applicable
        procedure_is_not_applicable
        procedure_flag_id
        procedure_status_id
        procedure_index
        financial_items
        quote_items
        estimate_items
        action_logs
        last_action_date
        dt_created
        dt_due
        dt_acknowledge_by
        acknowledge_by_utc
        last_action_date_utc
        created_date_utc
        due_date_utc
        dt_on_site_by
        dt_utc_on_site_by
        last_action_id
        induction_and_safety_status_id
        concurrency_id
        owner_id
        evaluation_ids_while_open
        latest_evaluation_id_after_closed
        source_company_id
        note_text
        completion_note_text
        raw_workorder_response
        created_at
        updated_at
        property_number
        assignee_screen_name
        failure_code
        created_by_name
        subtype_name
        failure_category
        total_spot_cost
        is_dst
    }
}`;



export default WorkOrders;